import React from 'react';
import { uniqueId } from 'lodash';
import {
	PrivacyTickProps,
	PrivacyTickRadioButtonType,
} from '../components/privacyTick/privacyTick';
import { setInfoDialogProps } from '../store/reducers/userSlice';
import { dispatch, store } from '../store/store';
import { openPdf } from '../utils/browserUtils';
import dichiarazione_antiriclaggio from '../assets/attachments/dichiarazione_antiriciclaggio.pdf';

export const initPrivacyTickListPartOne = [
	{
		groupName: 'flagProfilazioneDatiSocial',
		isRequired: false,
		title:
			'CONSENSO AD ESSERE SOTTOPOSTO A PROCESSI DECISIONALI AUTOMATIZZATI E LA PROFILAZIONE PER L’OFFERTA COMMERCIALE PERSONALIZZATA',
		message: (
			<p>
				Presto il consenso ad essere sottoposto a processi decisionali
				automatizzati, compresa la profilazione, per l’elaborazione di profili
				psicologici, sociologici e comportamentali per la gestione
				personalizzata del cliente al fine di proporre prodotti e servizi della
				Società, essendo consapevole del diritto di ottenere l’intervento umano
				da parte del Titolare del trattamento, di esprimere la mia opinione e di
				contestare la decisione.
			</p>
		),
		radioButtons: [
			{
				title: 'SI',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			},
			{
				title: 'NO',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},
		],
	},
	{
		groupName: 'flagConsensoCommerciale',
		isRequired: false,
		title: 'CONSENSO AL TRATTAMENTO PER FINALITÀ COMMERCIALI E PROMOZIONALI',
		message: (
			<p>
				Presto il consenso al trattamento dei miei dati personali per finalità
				commerciali, di marketing e promozionali, compreso l’invio di materiale
				illustrativo e pubblicitario, attraverso lettere, telefono, sms, e-mail,
				o altri sistemi automatici di comunicazione.
			</p>
		),
		radioButtons: [
			{
				title: 'SI',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			},
			{
				title: 'NO',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},
		],
	},
	{
		groupName: 'flagMarketingSoggTerzi',
		isRequired: false,
		title: 'COMUNICAZIONE DEI DATI A SOGGETTI TERZI PER FINALITÀ DI MARKETING',
		message: (
			<p>
				Presto il consenso alla comunicazione dei dati a soggetti terzi che
				svolgono attività di marketing di prodotti e/o servizi propri o di
				terzi.
			</p>
		),
		radioButtons: [
			{
				title: 'SI',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			},
			{
				title: 'NO',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},
		],
	},
] as PrivacyTickProps[];

export const initPrivacyTickListPartTwo = [
	{
		groupName: 'flagPoliticamenteEsposto',
		isRequired: false,
		title:
			'QUESTIONARIO AI SENSI DEL D.LGS. 21/11/2007 N. 231 E SUCCESSIVE MODIFICHE E INTEGRAZIONI - NORMATIVA ANTIRICICLAGGIO',
		message: (
			<p>
				<p>
					<b>Informativa</b>
				</p>
				<p>
					II sottoscritto rende le informazioni di cui alla presente richiesta
					(dati identificativi, attività lavorativa, situazione
					economico-patrimoniale, finalità ed importi della richiesta, ecc.)
					consapevole delle responsabilità penali in cui può incorrere ai sensi
					dell'art. 55 del D.Lgs. n. 231/2007 e successive modifiche e
					integrazioni, in caso di omissione o false informazioni, in conformità
					a quanto previsto dall'art. 22 del medesimo decreto, per consentire a
					Sella Personal Credit SpA di adempiere agli obblighi di adeguata
					verifica della clientela.Al fine di adempiere agli obblighi in materia
					di prevenzione e contrasto dell’uso del sistema economico e
					finanziario a scopo di riciclaggio e di finanziamento del terrorismo,
					dichiaro inoltre:
				</p>
				<p>
					<b>
						di ESSERE persona politicamente esposta{' '}
						<button>
							<img
								src="https://secure.sellapersonalcredit.it/econsel/images/icn_info_blue.png"
								width="25px"
								alt="info"
								onClick={() => {
									dispatch(
										setInfoDialogProps({
											...store.getState().user.infoDialogProps,
											title: 'Persona politicamente esposta',
											message: (
												<p>
													Sono considerate persone politicamente esposte le
													persone fisiche che occupano o hanno cessato di
													occupare da meno di un anno importanti cariche
													pubbliche, nonché i loro familiari e coloro che con i
													predetti soggetti intrattengono notoriamente stretti
													legami, come da elenco di cui all’art. 1, comma 2,
													lett. dd) del D.Lgs. 231/07 e s.m.i.
													<p>
														Per maggiori informazioni cliccare{' '}
														<a
															href={dichiarazione_antiriclaggio}
															target="_blank"
															// onClick={() => {
															// 	openPdf(dichiarazione_antiriclaggio);
															// }}
														>
															QUI
														</a>
													</p>
												</p>
											),
											isVisible: true,
										})
									);
								}}
							/>
						</button>
					</b>
				</p>
			</p>
		),
		radioButtons: [
			{
				title: 'SI',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			},
			{
				title: 'NO',
				isChecked: true,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},
		],
	},
	{
		groupName: 'flagTae',
		isRequired: false,
		title:
			'Svolgimento attività economica (TAE) collegata a "COMPRO-VENDO ORO", "MANDATARIO MARITTIMO", "MONEY TRANSFER" e/o "SALE CORSE/CASE DA GIOCO ',
		radioButtons: [
			{
				title: 'SI',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			},
			{
				title: 'NO',
				isChecked: true,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},
		],
	},
] as PrivacyTickProps[];

export const agreementPrivacyTicks = [
	{
		groupName: 'flagCondizioniGenerali',
		isRequired: true,
		title: 'Condizioni Generali',
		message: (
			<p>
				Dichiaro di aver preso chiara ed integrale visione delle 
				<a href="https://www.e-consel.it/public/content/file/Mod.NAMCA01.pdf">
					Condizioni Generali di contratto
				</a>
				 per l'emissione e rinnovo di certificati e di marche temporali.
			</p>
		),
		radioButtons: [
			{
				title: 'Acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			}/*,
			{
				title: 'Non acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},*/
		],
	},
	{
		groupName: 'flagManualeOperativo',
		isRequired: true,
		title: 'Manuale Operativo',
		message: (
			<p>
				Dichiaro di aver preso chiara ed integrale visione del Manuale Operativo
				e dei suoi addenda, in particolare dell'Addendum{' '}
				<a href="https://www.e-consel.it/public/content/file/NAM-FDMT-MO-ADD-DISP.pdf">
					Manuale Operativo - Certificati di Firma Disposable
				</a>
				.
			</p>
		),
		radioButtons: [
			{
				title: 'Acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			}/*,
			{
				title: 'Non acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},*/
		],
	},
	{
		groupName: 'flagInformativaPrivacy',
		isRequired: true,
		title: 'Informativa Privacy',
		message: (
			<p>
				Dichiaro di aver preso chiara ed integrale visione dell'
				<a href="https://www.e-consel.it/public/content/file/Informativa-privacy.pdf">
					Informativa Privacy
				</a>{' '}
				e, ai sensi dell'art. 13 del D.Lgs. 196/2003 e successive modificazioni,
				acconsento al trattamento dei dati personali necessari ad ottenere
				l'attivazione del rapporto oggetto del presente contratto (conferimento
				obbligatorio ai fini dell'instaurazione del rapporto).
			</p>
		),
		radioButtons: [
			{
				title: 'Acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			}/*,
			{
				title: 'Non acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},*/
		],
	},
	{
		groupName: 'flagModuloRichiesta',
		isRequired: true,
		title: 'Modulo di Richiesta',
		message: (
			<p>
				Dichiaro di aver letto il{' '}
				<a href="https://www.e-consel.it/public/content/file/Namirial_CA22D.pdf">
					Modulo di Richiesta
				</a>{' '}
				e di volerlo accettare in ogni sua parte.
			</p>
		),
		radioButtons: [
			{
				title: 'Acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			}/*,
			{
				title: 'Non acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},*/
		],
	},
	{
		groupName: 'flagModuloRichiesta2',
		isRequired: true,
		title: 'Obblighi e Responsabilità',
		message: (
			<p>
				Ai sensi e per gli effetti degli artt. 1341 e 1342 c.c., dichiaro di
				aver compreso e di accettare espressamente le clausole contenute nel
				Modulo di Richiesta al QUADRO D - nn. 2 e 6; nonché le clausole
				contenute nelle Condizioni Generali di Contratto di cui ai seguenti
				articoli: Art. 4 (Conclusione del contratto); Art. 5 (Requisiti hardware
				e software); Art. 7 (Revoca e sospensione dei Certificati); Art. 10
				(Obblighi e responsabilità del Titolare); Art. 11 (Garanzia e
				assistenza); Art. 12 (Obblighi e responsabilità del Certificatore); Art.
				13 (Clausola risolutiva espressa); Art. 14 (Recesso e informativa ex
				D.Lgs. 206/2005 e 70/2003); Art. 17 (Foro competente) - Non applicabile
				al cliente consumatore
			</p>
		),
		radioButtons: [
			{
				title: 'Acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			}/*,
			{
				title: 'Non acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},*/
		],
	},
	{
		groupName: 'flagClausoleVessatorie',
		isRequired: true,
		title: 'Trattamento dei dati',
		message: (
			<p>
				CONSENSI AL TRATTAMENTO DEI DATI PERSONALI Presa visione
				dell’informativa generale resa ai sensi degli artt. 13 e 14 del
				Regolamento (UE) 2016/679 sulla protezione dei dati personali delle
				persone fisiche e dell’informativa relativa al Codice di deontologia e
				di buona condotta per i sistemi informativi gestiti da soggetti privati
				in tema di crediti al consumo, affidabilità e puntualità nei pagamenti,
				prendo atto del trattamento dei miei dati effettuato da Sella Personal
				Credit S.p.A. per le finalità e con le modalità ivi previste. Inoltre,
				CONSENSO AL TRATTAMENTO PER FINALITÀ COMMERCIALI E PROMOZIONALI presto
				il consenso al trattamento dei miei dati personali per finalità
				commerciali, di marketing e promozionali, compreso l’invio di materiale
				illustrativo e pubblicitario, attraverso lettere, telefono, sms, e-mail,
				o altri sistemi automatici di comunicazione. CONSENSO ALLA COMUNICAZIONE
				DEI DATI A SOGGETTI TERZI PER FINALITA’ DI MARKETING presto il consenso
				alla comunicazione dei dati a soggetti terzi che svolgono attività di
				marketing di prodotti e/o servizi propri o di terzi. CONSENSO AD ESSERE
				SOTTOPOSTO A PROCESSI DECISIONALI AUTOMATIZZATI E A PROFILAZIONE PER
				L’OFFERTA COMMERCIALE PERSONALIZZATA presto il consenso ad essere
				sottoposto a processi decisionali automatizzati, compresa la
				profilazione, per l’elaborazione di profili psicologici, sociologici e
				comportamentali per la gestione personalizzata del cliente al fine di
				proporre prodotti e servizi della Società, essendo consapevole del
				diritto di ottenere l’intervento umano da parte del Titolare del
				trattamento, di esprimere la mia opinione e di contestare la decisione.
			</p>
		),
		radioButtons: [
			{
				title: 'Acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			}/*,
			{
				title: 'Non acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},*/
		],
	},
	{
		groupName: 'firma1',
		isRequired: true,
		title: 'Conferma Accettazione Consensi',
		message: (
			<p>
				Io sottoscritto dichiaro di avere fornito a Sella Personal Credit SpA
				tutte le informazioni riportate nella presente richiesta. Dichiaro
				altresì di aver scaricato: - copia della presente compilata in ogni sua
				parte, completa del documento Informazioni europee di base sul credito
				ai consumatori (SECCI); - l’Informativa generale ”Codice in materia di
				protezione dei dati personali”; - l’Informativa relativa al “Codice di
				deontologia e di buona condotta per i sistemi informativi gestiti da
				soggetti privati in tema di crediti al consumo, affidabilità e
				puntualità nei pagamenti”.
			</p>
		),
		radioButtons: [
			{
				title: 'Acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			}/*,
			{
				title: 'Non acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},*/
		],
	},
	{
		groupName: 'firma2',
		isRequired: true,
		title: 'Conferma Accettazione Clausole Finanziamento',
		message: (
			<p>
				Io sottoscritto dichiaro di aver letto e di accettare integralmente le
				condizioni generali del finanziamento di seguito riportate ed in
				particolare, anche per gli effetti dell’art. 1341 C.C., comma 2, di
				approvare espressamente: art. 1 (Conclusione del Contratto); art. 2
				(Obbligazioni del Cliente); art. 4 (Pagamenti); art. 5 (Rimborso
				anticipato); art. 6 (Ritardo nei pagamenti); art. 7 (Mancato pagamento,
				decadenza dal beneficio del termine e risoluzione del Contratto); );
				art. 8 (Oneri e spese); art. 9 (Modifica unilaterale delle condizioni
				contrattuali); art.10 (Comunicazioni al Cliente); art.13 (Foro
				competente e legge applicabile).
			</p>
		),
		radioButtons: [
			{
				title: 'Acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			}/*,
			{
				title: 'Non acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},*/
		],
	},
	{
		groupName: 'firma3',
		isRequired: true,
		title: 'Conferma Mandato',
		message: (
			<p>
				Mandato per addebito diretto SEPA La sottoscrizione del presente mandato
				comporta (A) l’autorizzazione a Sella Personal Credit SpA a richiedere
				alla banca del debitore l’addebito del suo conto e (B) l’autorizzazione
				alla banca del debitore di procedere a tale addebito conformemente alle
				disposizioni impartite da Sella Personal Credit SpA. L’addebito avrà
				carattere ricorrente e avverrà in applicazione delle condizioni previste
				nel documento riportante la Informazioni Europee di Base sul Credito ai
				Consumatori, frontespizio del contratto, allegate al presente mandato.
				Il debitore acconsente che Sella Personal Credit SpA richieda l’addebito
				del suo conto senza ulteriori notifiche preliminari. Il debitore ha
				diritto di ottenere il rimborso dalla propria Banca secondo gli accordi
				ed alle condizioni che regolano il rapporto con quest’ultima. Se del
				caso, il rimborso deve essere richiesto nel termine di 8 settimane a
				decorrere dalla data di addebito in conto.
			</p>
		),
		radioButtons: [
			{
				title: 'Acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			}/*,
			{
				title: 'Non acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},*/
		],
	},
	{
		groupName: 'firma4',
		isRequired: true,
		title: 'Conferma Accettazione Condizioni Generali Finanziamento',
		message: (
			<p>
				Io sottoscritto prendo atto ed accetto che la presente richiesta è
				regolata dalle condizioni economiche elencate nelle Informazioni Europee
				di Base sul Credito ai Consumatori allegate al presente fascicolo, che
				ne costituiscono parte integrante e sostanziale, e dichiaro di aver
				preso visione e di accettare le condizioni generali del finanziamento di
				seguito riportate.
			</p>
		),
		radioButtons: [
			{
				title: 'Acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.POSITIVE,
			}/*,
			{
				title: 'Non acconsento',
				isChecked: false,
				id: uniqueId(),
				type: PrivacyTickRadioButtonType.NEGATIVE,
			},*/
		],
	},
] as PrivacyTickProps[];

export const initPrivacyScopoList = [
	'Scopo prevalente/finalità del rapporto',
	'AUTOVEICOLI E MOTOVEICOLI',
	'ACCESSORI E RISTRUTTURAZIONE CASA',
	'CORSI E TEMPO LIBERO',
	'CURE MEDICHE ED ESTETIVA',
	'ALTRO',
];

export const initPrivacyPoliticamenteEspostoList = [
	'Provenienza del reddito',
	'REDDITO DA LAVORO DIPENDENTE',
	'REDDITO DA LAVORO AUTONOMO',
	'TITOLARE DI PENSIONE',
	'REDDITI DA PARTECIPAZIONE',
	'REDDITI DA FABBRICATO',
	'ALTRO',
];
