import React from 'react';
import Countdown from 'react-countdown';

const Completionist = () => <span>Tempo Scaduto!</span>;
const timeElementToString = (timeElement: number) => {
	return Number(timeElement) < 10 ? `0${timeElement}` : timeElement;
};

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
	if (completed) {
		// Render a completed state
		return <Completionist />;
	} else {
		// Render a countdown

		return (
			<span>
				{minutes ? `${timeElementToString(minutes)} minuti e ` : ''}
				{`${timeElementToString(seconds)} secondi`}
			</span>
		);
	}
};

interface IProps {
	startingDateMillis: number;
	durationMillis: number;
	onComplete?: any;
}

const CountDownTimer = (props: IProps) => {
	return (
		<>
			<Countdown
				date={props.startingDateMillis + props.durationMillis}
				renderer={(props) => renderer(props)}
				onComplete={props.onComplete}
			/>
		</>
	);
};

export default CountDownTimer;
