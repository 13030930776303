/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Button, ModalProps } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { uniqueId } from 'lodash';

export enum IDialogButtonType {
	SECONDARY = 'secondary',
	PRIMARY = 'primary',
}

export interface IDialogButton {
	title: string;
	type: IDialogButtonType;
	onClick: any;
}

export interface IConfirmDialogProps extends ModalProps {
	title: string;
	message: string | any;
	buttons: IDialogButton[];
	isVisible: boolean;
	//onHide: any;
	dialogStyle?: any;
}

const ConfirmDialog = (props: IConfirmDialogProps) => {
	return (
		<>
			<Modal
				show={props.isVisible}
				/* onHide={props.handleClose} */ dialogClassName={props.dialogStyle}
			>
				<Modal.Header closeButton>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p>{props.message}</p>
				</Modal.Body>

				<Modal.Footer>
					{props.buttons.map((value) => {
						return (
							<Button
								key={uniqueId()}
								variant={value.type}
								onClick={value.onClick}
							>
								{value.title}
							</Button>
						);
					})}
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ConfirmDialog;
