import * as React from 'react';
import { useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Combo } from '../../../../components/combo/Combo';
import ConfirmDialog, {
	IDialogButton,
	IDialogButtonType,
} from '../../../../components/confirmDialog/ConfirmDialog';
import PDFViewer from '../../../../components/pdfViewer/pdfViewer';
import PrivacyTick, {
	PrivacyTickProps,
	PrivacyTickRadioButtonType,
} from '../../../../components/privacyTick/privacyTick';
import {
	initPrivacyPoliticamenteEspostoList,
	initPrivacyScopoList,
	initPrivacyTickListPartOne,
	initPrivacyTickListPartTwo,
} from '../../../../config/initialData';
import usePrivacy from '../../../../hooks/usePrivacy';
import { ISignPrivacyRequest } from '../../../../services/api/spc-be/models/RequestModels';
import { IUserLoadResponse } from '../../../../services/api/spc-be/models/ResponseModels';
import {
	selectPrivacyFirstStepTicks,
	selectUserLoad,
	setPrivacyFirstStepTicks,
	setPrivacyTickProps,
} from '../../../../store/reducers/userSlice';
import { store } from '../../../../store/store';
import { FileDataType, showSaveAsDialog } from '../../../../utils/browserUtils';
import { mergePrivacyTickDataWithRedux } from './SecondStepContentController';
import './style.css';

const SecondStepContent = () => {
	const dispatch = useDispatch();
	const user: IUserLoadResponse = useSelector(selectUserLoad);
	const privacyFirstStepTicks: ISignPrivacyRequest = useSelector(
		selectPrivacyFirstStepTicks
	);

	const { privacy } = usePrivacy(user.codiceCliente, user.numeroPraticaRif);

	//	Actually mergeis commented cause the customer does not want radio prepopulated	//
	const [privacyTickListPartOne, setPrivacyTickListPartOne] = useState(
		initPrivacyTickListPartOne
		// mergePrivacyTickDataWithRedux(
		// 	initPrivacyTickListPartOne,
		// 	privacyFirstStepTicks
		// )
	);
	const [privacySelect] = useState(initPrivacyScopoList);
	const [politicamenteEspostoList] = useState(
		initPrivacyPoliticamenteEspostoList
	);
	const [privacyTickListPartTwo, setPrivacyTickListPartTwo] = useState(
		initPrivacyTickListPartTwo
		// mergePrivacyTickDataWithRedux(
		// 	initPrivacyTickListPartTwo,
		// 	privacyFirstStepTicks
		// )
	);

	const [isPoliticalDialogVisible, setIsPoliticalDialogVisible] = useState(
		false
	);

	const [isProvenienzaRedditoAltro, setIsProvenienzaRedditoAltro] = useState(
		false
	);

	const setPrivacyRedux = (privacyTick: PrivacyTickProps) => {
		const isChecked = privacyTick.radioButtons.find(
			(value) => value.type === PrivacyTickRadioButtonType.POSITIVE
		)?.isChecked;
		dispatch(
			setPrivacyFirstStepTicks({
				...store.getState().user.privacyFirstStepTicks,
				[`${privacyTick.groupName}`]: isChecked,
			})
		);
	};

	const setPrivacyComboRedux = (key: string, comboValue: string | number) => {
		dispatch(
			setPrivacyFirstStepTicks({
				...privacyFirstStepTicks,
				[key]: comboValue,
			})
		);
	};

	const downloadUnsignedPDF = () => {
		showSaveAsDialog(
			privacy,
			FileDataType.base64,
			'application/pdf',
			'unsigned_privacy.pdf'
		);
	};

	const onChangePrivacyListOne = (updatedProps: PrivacyTickProps) => {
		const updatedTicks = privacyTickListPartOne.map((v) => {
			return updatedProps.groupName !== v.groupName ? v : updatedProps;
		});
		setPrivacyTickListPartOne(updatedTicks);
		dispatch(setPrivacyTickProps([...privacyTickListPartTwo, ...updatedTicks]));
		setPrivacyRedux(updatedProps);
	};

	const onChangePrivacyListTwo = (
		updatedProps: PrivacyTickProps,
		selectedIndex: number
	) => {
		const updatedTicks = privacyTickListPartTwo.map((v) => {
			return updatedProps.groupName !== v.groupName ? v : updatedProps;
		});
		setPrivacyTickListPartTwo(updatedTicks);
		dispatch(setPrivacyTickProps([...privacyTickListPartOne, ...updatedTicks]));

		setPrivacyRedux(updatedProps);
		if (
			updatedProps.groupName === 'flagPoliticamenteEsposto' &&
			updatedProps.radioButtons[selectedIndex].isChecked
		) {
			if (
				updatedProps.radioButtons[selectedIndex].type ==
				PrivacyTickRadioButtonType.POSITIVE
			) {
				setIsPoliticalDialogVisible(true);
			} else {
				setPrivacyComboRedux('provenienzaDenaro', '');
			}
		}
	};

	return (
		<>
			<div>
				<PDFViewer file={privacy} />
				<br />
				{privacyTickListPartOne.map((value: PrivacyTickProps) => (
					<PrivacyTick
						key={value.groupName}
						onChange={onChangePrivacyListOne}
						{...value}
					/>
				))}
				{privacyTickListPartTwo.map((value: any, index: number) => (
					<PrivacyTick
						key={index}
						onChange={onChangePrivacyListTwo}
						{...value}
					/>
				))}

				{privacyFirstStepTicks.flagPoliticamenteEsposto ? (
					<div className="combo-container">
						<Combo
							title={
								'Provenienza dei redditi con cui verrà rimborsato il finanziamento'
							}
							list={politicamenteEspostoList}
							onChange={(selectedValue: any) => {
								setPrivacyComboRedux('provenienzaDenaro', selectedValue.label);
								setIsProvenienzaRedditoAltro(selectedValue.label === 'ALTRO');
							}}
						/>
						<br />
						{isProvenienzaRedditoAltro ? (
							<Form.Control
								onChange={(e) =>
									setPrivacyComboRedux('provenienzaDenaro', e.target.value)
								}
								type="text"
								placeholder="Specifica una provenienza"
								className="mt-10"
							/>
						) : null}
					</div>
				) : null}
				<div className="combo-container">
					<Combo
						title={'Scopo prevalente/finalità del rapporto'}
						list={privacySelect}
						onChange={(selectedValue: any) => {
							setPrivacyComboRedux(
								'scopoPrevalente',
								Number(selectedValue.value)
							);
						}}
						defaultSelectedIndex={privacyFirstStepTicks.scopoPrevalente}
					/>
				</div>
				<br />

				<p align="center">
					{privacy && (
						<Button
							className="preview-button"
							variant="primary"
							size="sm"
							onClick={() => {
								downloadUnsignedPDF();
							}}
						>
							Scarica il modulo
						</Button>
					)}
					<br />
					<br />
				</p>
				<ConfirmDialog
					title={'Conferma Necessaria'}
					message={
						'È stato inserito il flag su persona politicamente esposta. Conferma tale scelta?'
					}
					isVisible={isPoliticalDialogVisible}
					buttons={
						[
							{
								title: 'Annulla',
								type: IDialogButtonType.SECONDARY,
								onClick: () => {
									const updatedTicks: PrivacyTickProps[] = privacyTickListPartTwo.map(
										(value) => {
											return {
												...value,
												radioButtons: value.radioButtons.map((v) => {
													return {
														...v,
														isChecked:
															value.groupName === 'flagPoliticamenteEsposto'
																? v.type === PrivacyTickRadioButtonType.NEGATIVE
																	? true
																	: false
																: v.isChecked,
													};
												}),
											};
										}
									);

									const politicamenteEspostoTick = updatedTicks.find(
										(value) => value.groupName === 'flagPoliticamenteEsposto'
									);

									setPrivacyTickListPartTwo(updatedTicks);
									dispatch(
										setPrivacyTickProps([
											...updatedTicks,
											...privacyTickListPartOne,
										])
									);

									setPrivacyRedux(politicamenteEspostoTick as PrivacyTickProps);

									setIsPoliticalDialogVisible(false);
								},
							},
							{
								title: 'SI',
								type: IDialogButtonType.PRIMARY,
								onClick: () => {
									setIsPoliticalDialogVisible(false);
								},
							},
						] as IDialogButton[]
					}
				></ConfirmDialog>
			</div>
		</>
	);
};

export default SecondStepContent;
