import dotenv from 'dotenv';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import getHistory, {
	ReactRouterGlobalHistory,
} from 'react-router-global-history';
import { PersistGate } from 'redux-persist/integration/react';
import './App.css';
import ConfirmDialog, {
	IDialogButton,
	IDialogButtonType,
} from './components/confirmDialog/ConfirmDialog';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import ProgressDialog from './components/progressDialog/ProgressDialog';
import { LANDING } from './config/constPath';
import { devLandingParams } from './config/devData';
import { initialErrorDialogProps } from './config/initialStoreData';
import SPCRouter from './config/routerPago';
import { initUserLoad } from './containers/Landing/LandingController';
import { loadAxiosSPCBEConfigs } from './services/api/spc-be/axiosSPCBE';
import { ILandingRequest } from './services/api/spc-be/models/RequestModels';
import {
	selectCurrentLandingParams,
	selectErrorDialogProps,
	selectInfoDialogProps,
	setErrorDialogProps,
	setInfoDialogProps,
} from './store/reducers/userSlice';
import { dispatch, persistor } from './store/store';
import { queryStringToObject } from './utils/objUtils';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
dotenv.config({ path: '../.env' });

const App = () => {
	const [isProgressVisibile, setIsProgressVisible] = useState(false);
	const errorDialogProps = useSelector(selectErrorDialogProps);
	const infoDialogProps = useSelector(selectInfoDialogProps);
	const currentLandingParams = useSelector(selectCurrentLandingParams);

	const goToNextStepNumber = (nextStepNumber: number) => {
		getHistory().push(`/step${nextStepNumber}`);
	};

	const loadInitialData = () => {
		if (
			history.location.pathname === LANDING ||
			history.location.pathname === '/'
		) {
			dispatch(setErrorDialogProps(initialErrorDialogProps));
			const queryParams: ILandingRequest =
				process.env.REACT_APP_ACTIVE_DEVELOPMENT === 'true'
					? devLandingParams
					: queryStringToObject(window.location.search);

			if (!isEqual(currentLandingParams, queryParams)) {
				initUserLoad(queryParams, (currentStatus: number) => {
					goToNextStepNumber(currentStatus);
				});
			}
		}
	};

	useEffect(() => {
		loadAxiosSPCBEConfigs(
			() => {
				setIsProgressVisible(true);
			},
			() => {
				setIsProgressVisible(false);
			},
			() => {
				setIsProgressVisible(false);
			}
		);
		loadInitialData();
	}, []);

	const refreshPage = () => {
		// getCurrentStatus(
		// 	store.getState().user.userLoad,
		// 	(currentStatus: number) => {
		// 		goToNextStepNumber(currentStatus);
		// 	}
		// );
		// getHistory().push(``);
	};
	return (
		<>
			<div className="shell fill-page">
				<>
					<Header />
					<PersistGate loading={null} persistor={persistor}>
						<Router history={history}>
							<ReactRouterGlobalHistory />
							<ConfirmDialog
								{...errorDialogProps}
								buttons={
									[
										{
											title: 'Riprova',
											type: IDialogButtonType.PRIMARY,
											onClick: () => {
												dispatch(
													setErrorDialogProps({
														...errorDialogProps,
														isVisible: false,
													})
												);
												refreshPage();
											},
										},
									] as IDialogButton[]
								}
							></ConfirmDialog>
							<ConfirmDialog
								{...infoDialogProps}
								buttons={[
									{
										title: 'Chiudi',
										type: IDialogButtonType.PRIMARY,
										onClick: () => {
											dispatch(
												setInfoDialogProps({
													...infoDialogProps,
													isVisible: false,
												})
											);
										},
									},
								]}
							></ConfirmDialog>

							<ProgressDialog
								title="Attendere Prego..."
								isVisible={isProgressVisibile}
							/>

							<Route path={'/:filter?'} component={SPCRouter} />
						</Router>
					</PersistGate>
					<Footer />
				</>
			</div>
		</>
	);
};

export default App;
