import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as constPath from '../../config/constPath';
import Body from '../../components/body/body';
import {
	selectSelfieData,
	selectUserLoad,
	setErrorDialogProps,
	setSelfieData,
	setStepNumber,
} from '../../store/reducers/userSlice';
import { getSelfieURL, postSelfie } from '../../services/api/spc-be/userAPI';
import { store } from '../../store/store';

const ConfirmSelfie = (props: any) => {
	const dispatch = useDispatch();
	const selfieData = useSelector(selectSelfieData);
	const userLoadInfo = useSelector(selectUserLoad);
	const user = useSelector(selectUserLoad);

	const styleExampleConfermaSelfie = {
		backgroundImage: 'url(' + selfieData + ')',
	};

	useEffect(() => {
		if (!selfieData) {
			getSelfieURL(userLoadInfo.numeroPraticaRif, userLoadInfo.codiceCliente)
				.then((res) => {
					dispatch(setSelfieData(`${res.data.document}`));
				})
				.catch((err) => {
					console.debug(
						'Precedentemente non è stata inserita alcuna foto ' + err.toString()
					);
					dispatch(
						setErrorDialogProps({
							...store.getState().user.errorDialogProps,
							isVisible: false,
						})
					);
				});
		}
	}, []);

	const forwardHandler = () => {
		if (selfieData) {
			postSelfie(user.codiceCliente, user.numeroPraticaRif, {
				document: selfieData,
			}).then((res) => {
				dispatch(setStepNumber(constPath.NUM_STEP_AGREEMENT));
				props.history.push(constPath.AGREEMENT);
			});
		}
	};

	return (
		<>
			<Body
				stepNumber={3}
				title={'Scatta la tua foto!'}
				subtitle={
					'Controlla che la foto abbia i documenti ben in vista, non ci siano riflessi di luce e il tuo viso sia ben visibile.'
				}
				forwardPage={forwardHandler}
				backPage={() => {
					dispatch(setSelfieData(null));
					props.history.push(constPath.MAKE_SELFIE);
				}}
				nextButtonIsDisabled={!selfieData}
			>
				<section className="fill">
					<fieldset className="row">
						<div className="wrap">
							<div className="form">
								<div className="field">
									<input type="file" id="front" />
									<label htmlFor="front">
										<div className="drop">
											<div
												className="preview"
												style={styleExampleConfermaSelfie}
											></div>
										</div>
									</label>
								</div>
							</div>
						</div>
					</fieldset>
				</section>
			</Body>
		</>
	);
};

export default ConfirmSelfie;
