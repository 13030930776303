import _ from 'lodash';

export function queryStringToObject(queryString: string): any {
	return Array.from(new URLSearchParams(queryString).entries()).reduce(
		(prevValue, [key, value]) => {
			return {
				...prevValue,
				[key]: value,
			};
		},
		{}
	);
}

export function objectToQueryString(object: any): string {
	return '?' + _.map(object, (value, key) => `${key}=${value}`).join('&');
}
