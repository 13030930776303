import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
//import Cookies from 'js-cookie';

import Account, { IAccountProps } from '../account/account';
import NavStep from '../navStep/navStep';
import NavButton from '../navButton/navButton';
import { selectUserLoad } from '../../store/reducers/userSlice';

interface IBodyProps extends RouteComponentProps {
	stepNumber: number;
	title: string;
	subtitle: string;
	subSubTitle?: string;
	children: any;
	forwardPage?: () => void;
	backPage?: () => void;
	nextButtonIsDisabled?: boolean;
	accountProps?: IAccountProps;
	showNavButton?: boolean;
	stepisVisible?: boolean;
}

const Body = ({
	stepNumber,
	title,
	subtitle,
	subSubTitle,
	children,
	forwardPage,
	backPage,
	nextButtonIsDisabled,
	accountProps,
	showNavButton = true,
	stepisVisible = true,
}: IBodyProps) => {
	const userLoadInfo = useSelector(selectUserLoad);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className="inner-page-container">
				{!userLoadInfo && (
					<div className="spinner">
						<Spinner animation="border" role="status" variant="primary">
							<span className="sr-only">Loading...</span>
						</Spinner>
					</div>
				)}
				<Account
					name={userLoadInfo.nome}
					lastname={userLoadInfo.cognome}
					fiscalCode={userLoadInfo.codiceFiscale}
					amount={userLoadInfo.importoRata}
					{...accountProps}
				/>
				{stepisVisible && (
					<NavStep
						stepNumber={stepNumber}
						title={title}
						subtitle={subtitle}
						subSubTitle={subSubTitle}
					/>
				)}
				{children}
				{showNavButton && (
					<NavButton
						disabled={nextButtonIsDisabled}
						forwardPage={forwardPage}
						backPage={backPage}
					/>
				)}
			</div>
		</>
	);
};

export default withRouter(Body);
