import {
	IAgreementRequest,
	ILandingRequest,
	ISignPrivacyRequest,
} from '../services/api/spc-be/models/RequestModels';
import { IUserLoadResponse } from '../services/api/spc-be/models/ResponseModels';
import {
	IDialogButton,
	IDialogButtonType,
} from '../components/confirmDialog/ConfirmDialog';

export const initialLandingParams: ILandingRequest = {
	tipoPraticaRif: '',
	codiceCliente: '',
	numeroPraticaRif: '',
	tabellaFinanziaria: '',
	prodottoFinanziario: '',
	importoFinanziato: '',
	numeroRate: '',
	flagAssicurazione: '',
};

export const initialUserLoad: IUserLoadResponse = {
	id: 0,
	document: null,
	privacy: null,
	selfie: null,
	currentStatus: null,
	tipoPraticaRif: '',
	codiceCliente: '',
	numeroPraticaRif: '',
	tabellaFinanziaria: '',
	prodottoFinanziario: '',
	importoFinanziato: '',
	numeroRate: '',
	flagAssicurazione: '',
	importoRata: '',
	tan: '',
	taeg: '',
	importoAssicurazione: '',
	taegCpi: '',
	codice: '',
	nome: '',
	cognome: '',
	ragioneSociale: '',
	sesso: '',
	codiceFiscale: '',
	dataNascita: '',
	comuneNascita: '',
	provinciaNascita: '',
	cittadinanza: '',
	comuneResidenza: '',
	provinciaResidenza: '',
	indirizzoResidenza: '',
	capResidenza: '',
	tipoDocumento: '',
	numeroDocumento: '',
	dataScadenzaDoc: Date(),
	dataRilascioDoc: Date(),
	comuneRilascioDoc: '',
	provinciaRilascioDoc: '',
	email: '',
	telefono: '',
	iban: '',
	numeroTessera: '',
	dataScadenzaTessera: Date(),
};

export const initialPrivacyFirstStepTicks: ISignPrivacyRequest = {
	flagProfilazioneDatiSocial: false,
	flagConsensoCommerciale: false,
	flagMarketingSoggTerzi: false,
	flagTae: false,
	scopoPrevalente: 0,
	flagPoliticamenteEsposto: false,
	provenienzaDenaro: '',
};

export const initialErrorDialogProps = {
	title: 'Si è verificato un errore',
	message: 'Hai navigato alla pagina con parametri scorretti',
	isVisible: false,
	buttons: [],
};

export const initialInfoDialogProps = {
	title: 'Info',
	message: 'Questo è un dialog di info',
	isVisible: false,
	buttons: [],
};

export const initialAgreementRequest: IAgreementRequest = {
	flagCondizioniGenerali: false,
	flagManualeOperativo: false,
	flagInformativaPrivacy: false,
	flagModuloRichiesta: false,
	flagModuloRichiesta2: false,
	flagClausoleVessatorie: false,
	firma1: false,
	firma2: false,
	firma3: false,
	firma4: false,
};
