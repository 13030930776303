import { useEffect, useState } from 'react';
import { IUserLoadResponse } from '../services/api/spc-be/models/ResponseModels';
import { getPrivacyDocument } from '../services/api/spc-be/userAPI';

export default function usePrivacy(
	codiceCliente: IUserLoadResponse['codiceCliente'],
	numeroPraticaRif: IUserLoadResponse['numeroPraticaRif']
) {
	const [privacy, setPrivacy] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getPrivacyDocument(
					codiceCliente,
					numeroPraticaRif
				);
				setPrivacy(response.data.document);
			} catch (error) {
				console.debug(error.toString());
				// showErrorDialog(
				// 	'Errore',
				// 	'Si è verificato un errore durante il caricamento della privacy.'
				// );
			}
		};
		fetchData();
	}, []);

	return {
		privacy,
	};
}
