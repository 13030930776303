import {
	Action,
	combineReducers,
	configureStore,
	getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
} from 'redux-persist';
import { ThunkAction } from 'redux-thunk';
import { userPersistConfig } from './persist';
import userReducer, { IUserSlice } from './reducers/userSlice';

export const practiceReducers = combineReducers({
	user: persistReducer(userPersistConfig, userReducer),
});

export interface PracticeState {
	user: IUserSlice;
}

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	PracticeState,
	unknown,
	Action<string>
>;

export const store = configureStore({
	reducer: practiceReducers,
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	}),
});

export const persistor = persistStore(store);
export const { dispatch } = store;

export type RootState = ReturnType<typeof store.getState>;
