import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';

export interface IComboProps {
	title?: string;
	list: string[];
	onChange: any;
	defaultSelectedIndex?: number;
}
export const Combo = (props: IComboProps) => {
	const options = props.list.map((value, index) => {
		return { value: index, label: value };
	});
	return (
		<>
			{props.title ? <Form.Label>{props.title}</Form.Label> : ''}
			<br />
			<Select
				defaultValue={options[props.defaultSelectedIndex ?? 0]}
				options={options}
				onChange={(selectedOption) => {
					props.onChange(selectedOption);
				}}
			/>
		</>
	);
};
