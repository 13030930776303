import { useEffect, useState } from 'react';
import { IUserLoadResponse } from '../services/api/spc-be/models/ResponseModels';
import {
	getAgreementDocument,
	getAgreementDocumentIsReady,
} from '../services/api/spc-be/userAPI';

export const IS_READY_RETRY_PERIOD = 3000;

export default function useAgreement(
	codiceCliente: IUserLoadResponse['codiceCliente'],
	numeroPraticaRif: IUserLoadResponse['numeroPraticaRif']
) {
	const [agreement, setAgreement] = useState('');
	const [contractStatus, setContractStatus] = useState('KO');

	useEffect(() => {
		const fetchData = async () => {
			try {
				await getAgreementDocumentIsReady(
					codiceCliente,
					numeroPraticaRif,
					true
				);
				retrieveAgreementDocument();
				await callIsReady();
			} catch (error) {
				console.debug(error.toString());
				// showErrorDialog(
				// 	'Errore',
				// 	'Si è verificato un errore durante il caricamento della agreement.'
				// );
			}
		};
		fetchData();
	}, []);

	const callIsReady = async () => {
		try {
			let responseIsReady = false;
			while (!responseIsReady) {
				const response = await getAgreementDocumentIsReady(
					codiceCliente,
					numeroPraticaRif,
					true
				);
				responseIsReady = response.data.errorCode === 'READY';

				await setContractStatus(response.data.errorCode);

				if (!responseIsReady) {
					setTimeout(() => {
						console.log('NOT YET READY ');
					}, IS_READY_RETRY_PERIOD);
				}
			}
		} catch (e) {
			throw new Error('Error checking if agreement is ready ' + e.toString());
		}
	};

	const retrieveAgreementDocument = async () => {
		try {
			const response = await getAgreementDocument(
				codiceCliente,
				numeroPraticaRif
			);
			setAgreement(response.data.document);
		} catch (e) {
			console.debug('Error loading agreement ' + e.toString());
		}
	};

	return {
		contractStatus,
		agreement,
	};
}
