import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Body from '../../components/body/body';
import PDFViewer from '../../components/pdfViewer/pdfViewer';
import PrivacyTick, {
	PrivacyTickProps,
	PrivacyTickRadioButtonType,
} from '../../components/privacyTick/privacyTick';
import { agreementPrivacyTicks } from '../../config/initialData';
import useAgreement, { IS_READY_RETRY_PERIOD } from '../../hooks/useAgreement';
import { IUserLoadResponse } from '../../services/api/spc-be/models/ResponseModels';
import {
	selectAgreementTicks,
	selectUserLoad,
	setAgreementTicks,
	setErrorDialogProps,
	setStepNumber,
} from '../../store/reducers/userSlice';
import { downloadUnsignedPDF } from '../../utils/browserUtils';
import * as constPath from '../../config/constPath';
import ProgressDialog from '../../components/progressDialog/ProgressDialog';
import { getOTPRequest } from '../../services/api/spc-be/userAPI';
import { IAgreementRequest } from '../../services/api/spc-be/models/RequestModels';
import { validateAgreementTicks } from './AgreementController';
import { store } from '../../store/store';
import ConfirmDialog from '../../components/confirmDialog/ConfirmDialog';
//import mockedpdf from '../../assets/mocked/privacy.pdf';

const Agreement = (props: any) => {
	const dispatch = useDispatch();
	const user: IUserLoadResponse = useSelector(selectUserLoad);

	const [agreementTickList, setAgreementTickList] = useState(
		agreementPrivacyTicks
	);
	const agreementStoreTicks: IAgreementRequest = useSelector(
		selectAgreementTicks
	);
	const { contractStatus, agreement } = useAgreement(
		user.codiceCliente,
		user.numeroPraticaRif
	);

	const [isProgressDialogVisible, setIsProgressDialogVisible] = useState(false);

	const forwardHandler = () => {
		setIsProgressDialogVisible(true);
		while (contractStatus !== 'READY') {
			setTimeout(() => {
				console.log('NOT YET READY ');
			}, IS_READY_RETRY_PERIOD);
		}

		if (validateAgreementTicks(agreementTickList)) {
			getOTPRequest(
				user.numeroPraticaRif,
				user.codiceCliente,
				agreementStoreTicks
			).then((res) => {
				dispatch(setStepNumber(constPath.NUM_STEP_OTP_SIGN));
				props.history.push(constPath.OTP_SIGN);
			});
		} else {
			dispatch(
				setErrorDialogProps({
					...store.getState().user.errorDialogProps,
					message: 'Ricontrolla di aver accettato correttamente le clausole',
					isVisible: true,
				})
			);
		}
	};

	const onAgreementTickChange = (
		groupName: string,
		agreementTick: PrivacyTickProps
	) => {
		const updatedTicks = agreementTickList.map((v) => {
			return groupName !== v.groupName ? v : agreementTick;
		});
		setAgreementTickList(updatedTicks);
		dispatch(
			setAgreementTicks({
				...store.getState().user.agreementTicks,
				[`${groupName}`]: agreementTick.radioButtons.find(
					(value) => value.type == PrivacyTickRadioButtonType.POSITIVE
				)?.isChecked,
			})
		);
	};

	return (
		<>
			<Body
				stepNumber={4}
				title={'Prendi visione del Contratto'}
				subtitle={
					'Verifica la correttezza dei dati del contratto e indicaci come possiamo gestire al meglio la tua Privacy.'
				}
				forwardPage={() => forwardHandler()}
				backPage={() => {
					props.history.push(constPath.CONFIRM_SELFIE);
				}}
				//nextButtonIsDisabled={contractStatus !== 'READY'}
			>
				<section className="fill">
					<fieldset className="row">
						<div className="wrap">
							<div className="legend">
								<h2>Anteprima contratto</h2>
							</div>
							<div className="pdf">
								<div className="commands">
									<a
										className="btn sella small outline"
										onClick={() => downloadUnsignedPDF(agreement, 'contratto')}
										rel="noopener noreferrer"
										target="_blank"
									>
										Scarica il pdf
									</a>
								</div>
								{/* <PDFViewer file={mockedpdf} isNotBase64={true} /> */}
								{contractStatus !== 'KO' ? (
									<PDFViewer file={agreement} />
								) : (
									<ProgressDialog title="Contratto in preparazione" isVisible />
								)}
							</div>
							<br />
							{agreementTickList.map((value) => (
								<PrivacyTick
									key={value.groupName}
									onChange={(updatedProps: PrivacyTickProps) => {
										onAgreementTickChange(value.groupName, updatedProps);
									}}
									{...value}
								/>
							))}
						</div>
					</fieldset>
				</section>
				<ConfirmDialog
					isVisible={contractStatus !== 'READY' && isProgressDialogVisible}
					title={'Attendere'}
					message={<p>Stiamo preparando il tuo contratto firmato</p>}
					buttons={[]}
				></ConfirmDialog>
			</Body>
		</>
	);
};

export default Agreement;
