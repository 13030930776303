import { isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Body from '../../components/body/body';
import SPCLasciaRadoppia from '../../components/SPCLasciaRaddoppia/SPCLasciaRaddoppia';
import SPCSlotMachine from '../../components/SPCSlotMachine/SPCSlotMachine';
import * as constPath from '../../config/constPath';
import { initialUserLoad } from '../../config/initialStoreData';
import { IUserLoadResponse } from '../../services/api/spc-be/models/ResponseModels';
import { selectUserLoad, setStepNumber } from '../../store/reducers/userSlice';
import Disclaimer from './components/Disclaimer';
import './Landing.css';

const Landing = (props: any) => {
	const dispatch = useDispatch();
	const userLoad: IUserLoadResponse = useSelector(selectUserLoad);

	useEffect(() => {
		//initUserLoad(parsed, props);
	}, []);

	const forwardHandler = () => {
		dispatch(setStepNumber(constPath.NUM_STEP_PRIVACY));
		props.history.push(constPath.PRIVACY);
	};

	const isUserLoaded =
		userLoad?.codiceCliente !== '' && !isEqual(userLoad, initialUserLoad);
	return (
		<>
			<Body
				stepisVisible={false}
				stepNumber={0}
				title={"Abbiamo un'offerta esclusiva per te!"}
				subtitle={
					'APPpago ti apre un mondo di possibilità. Scopri subito quale ha riservato per te ed approfitta delle fantastiche promozioni del mondo Sella Personal Credit. Carte revolving, Prestiti e tassi promozionali sono pronti per te! Ecco il tuo premio:'
				}
				accountProps={{ showAmount: false }}
				showNavButton={false}
			>
				<div>
					<SPCLasciaRadoppia />
					<div className="landing-slots-container">
						<SPCSlotMachine couldStartAnimation={isUserLoaded} />
					</div>
				</div>
				<Row className="landing-row">
					<Col className="col-6 offset-3 landing-button-container">
						<Button
							className="shadowed landing-start-button"
							onClick={() => forwardHandler()}
						>
							RICHIEDILO SUBITO
						</Button>
					</Col>
				</Row>
				<Disclaimer
					visible={isUserLoaded}
					userLoad={userLoad}
					className="disclaimer"
				/>
			</Body>
		</>
	);
};

export default Landing;
