import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import Body from '../../components/body/body';
import {
	selectSignedPrivacyDocumentBase64,
	setStepNumber,
} from '../../store/reducers/userSlice';
import { dispatch, store } from '../../store/store';
import * as constPath from '../../config/constPath';
import FirstStepContent from './privacySteps/FirstPrivacyStep/FirstStepContent';
import SecondStepContent from './privacySteps/SecondPrivacyStep/SecondStepContent';
import ThirdPrivacyStepContent from './privacySteps/ThirdPrivacyStep/ThirdPrivacyStepContent';
import './style.css';
import { submitPrivacy, validatePrivacyTicks } from './PrivacyController';
import { useSelector } from 'react-redux';
import { imgLogoBianco } from '../../assets/images';

const Privacy = (props: any) => {
	const [currentStep, setCurrentStep] = useState(0);
	const signedPrivacyDocumentBase64 = useSelector(
		selectSignedPrivacyDocumentBase64
	);

	const forwardHandler = () => {
		dispatch(setStepNumber(constPath.NUM_STEP_DOCUMENTS));
		props.history.push(constPath.DOCUMENTS);
	};

	const step2Validator = () => {
		window.scrollTo(0, 0);
		return validatePrivacyTicks(
			store.getState().user.privacyTickProps,
			store.getState().user.privacyFirstStepTicks
		);
	};

	const step3Validator = () => {
		window.scrollTo(0, 0);
		return signedPrivacyDocumentBase64 != null;
	};

	return (
		<>
			<Body
				stepNumber={1}
				title={'Facci sapere come possiamo gestire al meglio la tua Privacy'}
				subtitle={
					"In questa fase potrai prendere visione dell'informativa Privacy e di come vengono trattati i dati in totale trasparenza. Potrai confermare o meno i consensi per ogni singolo trattamento proposto ed inoltre scaricare l'informativa e tenere una copia per te."
				}
				// backPage={() => props.history.push(getIndexPath())}
				// forwardPage={() => forwardHandler()}
				// nextButtonIsDisabled={!signedPrivacyDocumentBase64}
				showNavButton={false}
			>
				<section className="fill">
					<fieldset className="row">
						<div className="wrap">
							<div className="bg-primary text-white">
								<div className="privacy-section-title">
									<img src={imgLogoBianco} className="logo-img" alt="APPpago" />
								</div>
							</div>
							<div className="steppers">
								<StepProgressBar
									startingStep={0}
									onSubmit={() => forwardHandler()}
									nextBtnName={
										currentStep == 0 ? 'Procedi' : 'Conferma le mie scelte'
									}
									submitBtnName="Continua"
									previousBtnName="Indietro"
									primaryBtnClass={
										currentStep == 0
											? 'privacy-first-step'
											: 'privacy-second-step'
									}
									secondaryBtnClass="privacy-prev-button"
									steps={[
										{
											label: '',
											subtitle: '',
											name: 'step 1',
											content: <FirstStepContent />,
											validator: () => {
												setCurrentStep(currentStep + 1);
												return true;
											},
										},
										{
											label: '',
											subtitle: '',
											name: 'step 2',
											content: <SecondStepContent />,
											validator: () => {
												setCurrentStep(currentStep + 1);
												submitPrivacy();
												return step2Validator();
											},
										},
										{
											label: '',
											subtitle: '',
											name: 'step 3',
											content: <ThirdPrivacyStepContent />,
											validator: () => step3Validator(),
										},
									]}
								/>
							</div>
						</div>
					</fieldset>
				</section>
			</Body>
		</>
	);
};

export default Privacy;
