import React from 'react';
import { Navbar } from 'react-bootstrap';
import './NavButton.css';

const NavButton = (props) => {
	let buttons = (
		<>
			{props.backPage && (
				<button
					className="btn sella secondary outline small"
					onClick={props.backPage}
				>
					Indietro
				</button>
			)}
			{props.forwardPage && (
				<button
					disabled={props.disabled}
					className="btn sella"
					onClick={props.forwardPage}
				>
					Avanti
				</button>
			)}
		</>
	);

	return (
		<>
			<Navbar className="navbutton-navbar">
				<Navbar.Collapse className="justify-content-center">
					<div className="navbutton-info-container">
						<div className="navbutton-buttons-container">{buttons}</div>
					</div>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

export default NavButton;
