import {
	initialPrivacyFirstStepTicks,
	initialAgreementRequest,
	initialUserLoad,
} from '../../config/initialStoreData';
//import { userPersistConfig } from '../persist';
import { dispatch } from '../store';
import {
	setSelfieData,
	setSignedPrivacyDocumentBase64,
	setPrivacyFirstStepTicks,
	setAgreementTicks,
	setPrivacyTickProps,
	setUserLoad,
} from './userSlice';

export function resetUserSlice() {
	//userPersistConfig.storage.removeItem('persist:user');
	//dispatch(setUserLoad(initialUserLoad));
	dispatch(setSelfieData(null));
	dispatch(setSignedPrivacyDocumentBase64(null));
	dispatch(setPrivacyFirstStepTicks(initialPrivacyFirstStepTicks));
	dispatch(setAgreementTicks(initialAgreementRequest));
	dispatch(setPrivacyTickProps(new Array()));
}
