import { PrivacyTickProps, PrivacyTickRadioButtonType } from './privacyTick';

function validateTick(privacyTick: PrivacyTickProps): boolean {
	if (privacyTick.isRequired) {
		return (
			privacyTick.radioButtons.find(
				(value) =>
					value.type === PrivacyTickRadioButtonType.POSITIVE && value.isChecked
			) !== undefined
		);
	} else {
		// if not required almost one radio should be checked by the user
		return (
			privacyTick.radioButtons.find((value) => value.isChecked) !== undefined
		);
	}
}

export function validateTicks(privacyTicks: PrivacyTickProps[]): boolean {
	return privacyTicks.reduce((previousValue, currentValue) => {
		return previousValue && validateTick(currentValue);
	}, true as boolean);
}
