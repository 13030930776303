import * as React from 'react';
import { IUserLoadResponse } from '../../../services/api/spc-be/models/ResponseModels';

interface DisclaimerProps {
	visible: boolean;
	userLoad: IUserLoadResponse;
	className: string;
}

const Disclaimer = ({ visible, userLoad, className }: DisclaimerProps) => {
	return visible ? (
		<p className={className}>
			Messaggio pubblicitario con finalità promozionale. Per maggiori
			informazioni è disponibile sul sito il modulo "Informazioni europee di
			base sul credito ai consumatori" (SECCI) e la copia del testo{' '}
			contrattuale. Offerta valida fino al{' '}
			{new Date(Date.parse(userLoad.dataFinePromozione)).toLocaleDateString(
				'it-It',
				{
					weekday: 'long',
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				}
			)}{' '}
			per le richieste di prestito personale online. Salvo approvazione di Sella
			Personal Credit SpA. Esempio rappresentativo: importo richiesto{' '}
			{userLoad.importoFinanziato} euro da rimborsare in {userLoad.numeroRate} rate
			mensili da {userLoad.importoRata} euro. TAN FISSO {userLoad.tan}
			%, TAEG {userLoad.taeg}%. Importo totale dovuto dal consumatore (importo
			totale del credito + costo totale del credito){' '}
			{userLoad.importoTotale} euro. Importo totale del credito{' '}
			{userLoad.importoFinanziato} euro. Il costo totale del credito comprende:
			interessi calcolati al TAN indicato, oneri fiscali (imposta sostitutiva
			 {Number(userLoad.oneriFiscali).toFixed(2)} euro*) addebitati
			sulla prima rata, costo mensile di gestione pratica 0,00 euro, spesa di
			istruttoria 0,00 euro, spesa per invio rendicontazione periodica cartacea
			0,98 euro, imposta di bollo su rendicontazione periodica 0,00 euro.
			Modalità di rimborso obbligatoria: addebito diretto su c/c. La scadenza
			delle rate è determinata dal giorno della liquidazione del contratto; la
			data di scadenza delle rate è prevista il giorno{' '}
			{userLoad.giornoScadenzaRata} del mese. L'importo di ciascuna rata
			comprende una quota di capitale crescente e interessi decrescente secondo
			un piano di ammortamento "alla francese". l’imposta sostitutiva di {' '}
			{Number(userLoad.oneriFiscali).toFixed(2)} euro è pari allo {userLoad.oneriFiscaliRequest}% 
			dell’importo finanziato ed è alternativa all’imposta di bollo su contratto.
		</p>
	) : null;
};

export default Disclaimer;
