import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';

export const downloadUnsignedPDF = (pdf: string, pdfTitle: string) => {
	showSaveAsDialog(
		pdf,
		FileDataType.base64,
		'application/pdf',
		`${pdfTitle}.pdf`
	);
};

export enum FileDataType {
	base64,
	blob,
}
export function showSaveAsDialog(
	file: any,
	fileType: FileDataType,
	mimetype: string,
	filename: string
) {
	let blobData = null;
	if (fileType == FileDataType.base64) {
		blobData = new Blob([base64StringToBlob(file, mimetype)], {
			type: mimetype,
		});
	} else {
		blobData = file;
	}

	saveAs(blobData, filename);
}

export function openPdf(pdf: string) {
	const pdfWindow = window.open('');
	pdfWindow.document.write(
		"<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
			encodeURI(pdf) +
			"'></iframe>"
	);
}
