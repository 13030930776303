/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

export enum PrivacyTickRadioButtonType {
	POSITIVE,
	NEGATIVE,
	NEUTRAL,
}
export interface PrivacyTickRadioButton {
	type: PrivacyTickRadioButtonType;
	title: string;
	isChecked: boolean;
	id: string;
}
export interface PrivacyTickProps {
	groupName: string;
	isRequired: boolean;
	title: string;
	message?: string | any;
	radioButtons: PrivacyTickRadioButton[];
	onChange?: (updatedProps: PrivacyTickProps, selectedIndex: number) => void;
}

const PrivacyTick = (props: PrivacyTickProps) => {
	const [tick, setTick] = useState(props);

	const onChange = (value: React.FormEvent<HTMLFormElement>) => {
		const buttonID = value.target.id;
		if (props.onChange) {
			const updatedTick = {
				...props,
				radioButtons: props.radioButtons.map((v) => {
					return {
						...v,
						isChecked: v.id !== buttonID ? false : true,
					};
				}),
			};
			setTick(updatedTick);
			props.onChange(
				updatedTick,
				props.radioButtons.findIndex((value) => value.id === buttonID)
			);
		}
	};

	return (
		<>
			<div className="legend">
				<h3>{props.title}</h3>
				<p>{props.message}</p>
			</div>

			<Form
				inline
				onChange={(value) => {
					onChange(value);
				}}
				className="form"
			>
				<div className="field">
					{tick.radioButtons.map((value, index) => (
						<>
							<Form.Check
								inline
								label={value.title}
								key={value.id}
								type="radio"
								id={value.id}
								name={props.groupName}
								checked={value.isChecked}
							/>
							<br />
						</>
					))}
				</div>
			</Form>
		</>
	);
};

export default PrivacyTick;
