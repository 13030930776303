import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NUM_STEP_LANDING } from '../../config/constPath';
import { getIndexPath } from '../../config/routerPago';
import { selectStepNumber } from '../../store/reducers/userSlice';
import './ErrorPage.css';

const ErrorPage = (props: any) => {
	const currentStepNumber = useSelector(selectStepNumber);

	const goBack = () => {
		if (currentStepNumber === NUM_STEP_LANDING) {
			props.history.push(getIndexPath());
		} else {
			props.history.push(`/step${currentStepNumber}`);
		}
	};
	return (
		<>
			<Row className="error-page-row">
				<Col className="col-6 offset-3">
					<div className="error-page-title">
						<h2 className="title">Ops! Qualcosa è andato storto</h2>
						<p>Contattare il servizio clienti.</p>
						<p>Grazie</p>
						<Button
							className="btn sella outline small"
							variant="primary"
							size="lg"
							onClick={goBack}
						>
							Torna Indietro
						</Button>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default ErrorPage;
