import React from 'react';
import { useSelector } from 'react-redux';
import PDFViewer from '../../../../components/pdfViewer/pdfViewer';
import { selectSignedPrivacyDocumentBase64 } from '../../../../store/reducers/userSlice';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as constPath from '../../../../config/constPath';
import { Button } from 'react-bootstrap';
import { downloadUnsignedPDF } from '../../../../utils/browserUtils';

const ThirdPrivacyStepContent = (props: any) => {
	const signedPrivacyDocumentBase64 = useSelector(
		selectSignedPrivacyDocumentBase64
	);

	return (
		<>
			<div>
				{signedPrivacyDocumentBase64 ? (
					<>
						<PDFViewer file={signedPrivacyDocumentBase64} />
						<br />
						<br />
						<section className="otp centered fill">
							<div className="commands">
								<a
									className="btn sella small outline"
									onClick={() =>
										downloadUnsignedPDF(
											signedPrivacyDocumentBase64,
											'privacy_firmata'
										)
									}
									rel="noopener noreferrer"
									target="_blank"
								>
									Scarica il Documento
								</a>
							</div>
						</section>
					</>
				) : (
					<>
						<section className="otp centered fill">
							<div className="commands">
								<p>Si è verificato un errore nella firma del Documento</p>
								<Button
									className="btn sella small outline"
									variant="primary"
									size="lg"
									onClick={() => props.history.push(constPath.CONFIRM_SELFIE)}
								>
									Torna Indietro
								</Button>
							</div>
						</section>
					</>
				)}
			</div>
		</>
	);
};

export default ThirdPrivacyStepContent;
