import React from 'react';
import { Navbar } from 'react-bootstrap';
import { imgLogo } from '../../assets/images';
import './Footer.css';

const Footer = () => {
	return (
		<>
			<Navbar className="footer-navbar">
				<Navbar.Collapse className="justify-content-center">
					<div className="footer-logo-info-container">
						<Navbar.Brand className="footer-navbar-brand">
							<img src={imgLogo} className="logo-img" alt="APPpago" />
						</Navbar.Brand>
						<Navbar.Text>
							<div className="footer-text">
								<p className="description">
									Società soggetta all'attività di direzione e coordinamento
									della Banca Sella Holding SpA Via V. Bellini 2 - 10121 Torino
									PEC: sellapersonalcredit@actaliscertymail.it Società
									appartenente al Gruppo Iva Maurizio Sella S.A.A. con P. IVA
									02675650028 Cod.Fisc. / Num. iscrizione presso l’Ufficio del
									Registro di Torino 02007340025 R.E.A. n. 948365 Capitale
									sociale e riserve: 91.772.082,15 euro
								</p>
								<p className="copyright">
									&copy; 2020 APPpago - Tutti i diritti riservati
								</p>
							</div>
						</Navbar.Text>
					</div>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

export default Footer;
