import React, { useCallback, useRef } from 'react';
import { Button } from 'react-bootstrap';
import Webcam from 'react-webcam';

const SPCWebcam = ({ setSelfie }) => {
	const webcamRef = useRef(null);
	const capture = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		setSelfie(imageSrc);
	}, [webcamRef]);

	return (
		<>
			<div
				id="container"
				style={{
					display: 'block',
					margin: '0px auto',
					width: '100%',
					height: '375px',
					//border: '10px #333 solid',
					alignContent: 'center',
					alignItems: 'center',
					alignSelf: 'center',
					textAlign: 'center',
				}}
			>
				<Webcam
					style={{
						width: '100%',
						height: '375px',
						//backgroundColor: '#666',
					}}
					ref={webcamRef}
					audio={false}
					videoConstraints={{ facingMode: 'user' }}
					screenshotFormat="image/jpeg"
					forceScreenshotSourceSize="true"
				/>
			</div>
			<fieldset className="row">
				<div className="wrap" style={{ textAlign: 'center' }}>
					<Button onClick={capture} className="btn sella outline small">
						Scatta
					</Button>
				</div>
			</fieldset>
		</>
	);
};

export default SPCWebcam;
