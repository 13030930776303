import React from 'react';
import { Modal, Button, Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CustomModal.css';

const CustomModal = (props: any) => {
	return (
		<>
			<Modal show={props.show} onHide={props.handleClose} size="lg" centered>
				<Modal.Header closeButton />
				<Modal.Body>
					<Row>
						<Col className="col-8 offset-2">
							<Image className="moda-img" src={props.src} rounded />
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={props.handleClose}>
						Chiudi
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default CustomModal;
