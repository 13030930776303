import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { ERROR_PAGE, routes } from '../../config/constPath';
import { selectStepNumber } from '../../store/reducers/userSlice';

interface SPCRouteProps {
	path: string;
}

const SPCRoute = ({ path }: SPCRouteProps) => {
	const status = useSelector(selectStepNumber);
	return process.env.REACT_APP_ACTIVE_DEVELOPMENT === 'true' ||
		routes[path].stepNumber <= status ? (
		<Route exact path={path} component={routes[path].component} />
	) : (
		<Redirect to={ERROR_PAGE} />
	);
};
export default SPCRoute;
