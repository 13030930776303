import React from 'react';
import { Navbar } from 'react-bootstrap';
import './NavStep.css';

interface NavstepInterface {
	stepNumber: number;
	title: string;
	subtitle: string;
	subSubTitle?: string;
}

const NavStep: React.FC<NavstepInterface> = ({
	stepNumber,
	title,
	subtitle,
	subSubTitle,
}) => {
	return (
		<>
			<Navbar className="navstep-navbar">
				<Navbar.Collapse className="justify-content-center">
					<div className="navstep-info-container">
						<div
							className="navstep-progress"
							data-step={stepNumber}
							data-total="7"
						></div>
						<Navbar.Text>
							<div className="navstep-info">
								<p className="title">{title}</p>
								<p className="subtitle">{subtitle}</p>
								{subSubTitle && <p className="sub-subtitle">{subSubTitle}</p>}
							</div>
						</Navbar.Text>
					</div>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

export default NavStep;
