import React from 'react';
import './style.css';

const FirstStepContent = () => {
	return (
		<>
			<div className="fist-step-content-title">
				<h1>Sella</h1>
				<h3>Personal Credit</h3>
			</div>
			<div className="first-step-content-welcome">
				<h1>Benvenuto!</h1>
				<h2>Procedi per visualizzare il modulo Privacy</h2>
			</div>
		</>
	);
};

export default FirstStepContent;
