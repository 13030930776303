import { PrivacyTickProps } from '../../components/privacyTick/privacyTick';
import { validateTicks } from '../../components/privacyTick/privacyTickValidator';
import { ISignPrivacyRequest } from '../../services/api/spc-be/models/RequestModels';
import { IUserLoadResponse } from '../../services/api/spc-be/models/ResponseModels';
import { postSignedPrivacy } from '../../services/api/spc-be/userAPI';
import { setSignedPrivacyDocumentBase64 } from '../../store/reducers/userSlice';
import { dispatch, store } from '../../store/store';

export function validatePrivacyTicks(
	privacyTicks: PrivacyTickProps[],
	privacyRequest: ISignPrivacyRequest
): boolean {
	return (
		validateTicks(privacyTicks) &&
		// privacyTicks.flagProfilazioneDatiSocial &&
		// privacyTicks.flagConsensoCommerciale &&
		// privacyTicks.flagMarketingSoggTerzi &&
		// privacyTicks.flagTae &&
		privacyRequest.scopoPrevalente != 0 &&
		(privacyRequest.flagPoliticamenteEsposto
			? privacyRequest.provenienzaDenaro != null &&
			  privacyRequest.provenienzaDenaro !== ''
			: true)
	);
}

export const submitPrivacy = () => {
	const user: IUserLoadResponse = store.getState().user.userLoad;
	const privacyFirstStepTicks: ISignPrivacyRequest = store.getState().user
		.privacyFirstStepTicks;

	const validation = validatePrivacyTicks(
		store.getState().user.privacyTickProps,
		privacyFirstStepTicks
	);
	if (validation) {
		postSignedPrivacy(
			user.codiceCliente,
			user.tipoPraticaRif,
			user.numeroPraticaRif,
			privacyFirstStepTicks
		).then((res) => {
			dispatch(setSignedPrivacyDocumentBase64(res.data.document));
		});
	} else {
		//throw new Error('privacy ticks are not validated');
	}
};
