import React from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProgressDialog.css';

interface IProps {
	title: string;
	isVisible: boolean;
}

const ProgressDialog = (props: IProps) => {
	return (
		<>
			<Modal show={props.isVisible} centered>
				<Modal.Header>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col className="col-6 spinner-inside-modal">
							<Spinner animation="border" variant="primary" />
							<span className="spinner-text">Caricamento...</span>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ProgressDialog;
