import React, { useState } from 'react';
import './SPCSlotMachine.css';

interface IProps {
	gif: string;
	firstFrame: string;
	couldStartAnimation: boolean;
	children: any;
	time: number;
}

const Slot = (props: IProps) => {
	const { gif, firstFrame, couldStartAnimation, children, time } = props;
	//const [couldStartAnimation, setCouldStartAnimation] = useState(false);
	const [isPlaying, setIsPlaying] = useState(true);

	// useEffect(()=>{
	// 	setTimeout(() => setCouldStartAnimation(true), animationDelayMillis)
	// },[]);
	return (
		<div className="single-slot">
			<div className="slot">
				<img
					className="gif"
					onLoad={
						couldStartAnimation
							? () => setTimeout(() => setIsPlaying(false), time)
							: () => {}
					}
					src={couldStartAnimation ? gif : firstFrame}
				/>
				{isPlaying || <div className="slot-inline">{children} </div>}
			</div>
		</div>
	);
};

export default Slot;
