import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Button, Spinner } from 'react-bootstrap';
import { pdfjs } from 'react-pdf';
import { openPdf } from '../../utils/browserUtils';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {
	file: any;
	isNotBase64?: boolean;
}

const PDFViewer = (props: IProps) => {
	const { file } = props;
	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = (opt: pdfjs.PDFDocumentProxy) => {
		const { numPages } = opt;
		setNumPages(numPages);
		setPageNumber(1);
	};

	const spinner = (
		<div className="spinner">
			<Spinner animation="border" role="status" variant="primary" />
		</div>
	);
	return (
		<div className="pdf-shell">
			<Document
				onClick={() => openPdf(file)}
				file={props.isNotBase64 ? file : `data:application/pdf;base64,${file}`}
				onLoadSuccess={onDocumentLoadSuccess}
				error={'Non è stato possibile caricare il PDF'}
				loading={spinner}
			>
				<Page pageNumber={pageNumber} width={552} />
			</Document>
			<div className="second-step-content-page-number">
				<Button
					className="page-number-previous"
					variant="primary"
					size="sm"
					disabled={pageNumber <= 1}
					onClick={() => {
						setPageNumber(pageNumber - 1);
					}}
				>
					{'<'}
				</Button>
				<div className="page-number-text">
					<span>
						Pagina {pageNumber || (numPages > 0 ? 1 : '--')} di{' '}
						{numPages || '--'}
					</span>
				</div>
				<Button
					className="page-number-next"
					variant="primary"
					size="sm"
					disabled={pageNumber >= numPages}
					onClick={() => {
						setPageNumber(pageNumber + 1);
					}}
				>
					{'>'}
				</Button>
			</div>
		</div>
	);
};

export default PDFViewer;
