import React from 'react';
import { Navbar } from 'react-bootstrap';
import './Account.css';

export interface IAccountProps {
	name?: string;
	lastname?: string;
	fiscalCode?: string;
	amount?: string;
	showFiscalCode?: boolean;
	showName?: boolean;
	showAmount?: boolean;
}

const Account = ({
	name,
	lastname,
	fiscalCode,
	amount,
	showFiscalCode = true,
	showName = true,
	showAmount = true,
}: IAccountProps) => {
	return (
		<>
			<Navbar className="account-navbar">
				<Navbar.Collapse className="justify-content-center">
					<div className="account-info-container">
						{showName && (
							<Navbar.Text>
								<div className="account-client">
									<p className="label">Cliente</p>
									<p className="client">
										{name} {lastname}
									</p>
								</div>
							</Navbar.Text>
						)}
						{showFiscalCode && (
							<Navbar.Text>
								<div className="account-cf">
									<p className="label">Codice fiscale</p>
									<p className="fiscal-code">{fiscalCode}</p>
								</div>
							</Navbar.Text>
						)}
						{showAmount && (
							<Navbar.Text>
								<div className="account-amount">
									<p className="label">Importo Rata</p>
									<p className="amount">{Number(amount).toFixed(2)} &euro;</p>
								</div>
							</Navbar.Text>
						)}
					</div>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

export default Account;
