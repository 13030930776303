import { useEffect, useState } from 'react';
import { IUserLoadResponse } from '../services/api/spc-be/models/ResponseModels';
import { getSignedAgreementDocument } from '../services/api/spc-be/userAPI';

export default function useSignedAgreement(
	codiceCliente: IUserLoadResponse['codiceCliente'],
	numeroPraticaRif: IUserLoadResponse['numeroPraticaRif']
) {
	const [signedAgreement, setSignedAgreement] = useState<string>('');

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getSignedAgreementDocument(
					codiceCliente,
					numeroPraticaRif
				);
				setSignedAgreement(response.data.document);
			} catch (error) {
				console.debug(error.toString());
				// showErrorDialog(
				// 	'Errore',
				// 	'Si è verificato un errore durante il caricamento della signedAgreement firmata.'
				// );
			}
		};
		fetchData();
	}, []);

	return {
		signedAgreement,
	};
}
