import { IAgreementRequest } from './models/RequestModels';

const subRoute = 'SPCBackendApplication/';

export const USER_LOAD_URL = `${subRoute}user/load`;
export const currentStatusURL = (
	codiceCliente: string,
	numeroPraticaRif: string
) => `${subRoute}check/status/${codiceCliente}/${numeroPraticaRif}`;

export const unsignedPrivacyURL = `${subRoute}user/get/privacy`;

export const signedPrivacyURL = (
	tipoPraticaRif: string,
	numeroPraticaRif: string,
	codiceCliente: string
) =>
	`${USER_LOAD_URL}/privacy/sign/${tipoPraticaRif}/${numeroPraticaRif}/${codiceCliente}`;
export const ciFronteURL = (codiceCliente: string, numeroPraticaRif: string) =>
	`${USER_LOAD_URL}/documents/idFronte/${codiceCliente}/${numeroPraticaRif}`;
export const ciRetroURL = (codiceCliente: string, numeroPraticaRif: string) =>
	`${USER_LOAD_URL}/documents/idRetro/${codiceCliente}/${numeroPraticaRif}`;
export const cfFronteURL = (codiceCliente: string, numeroPraticaRif: string) =>
	`${USER_LOAD_URL}/documents/cfFronte/${codiceCliente}/${numeroPraticaRif}`;
export const cfRetroURL = (codiceCliente: string, numeroPraticaRif: string) =>
	`${USER_LOAD_URL}/documents/cfRetro/${codiceCliente}/${numeroPraticaRif}`;
export const postselfieURL = (
	numeroPraticaRif: string,
	codiceCliente: string
) => `${subRoute}user/upload/selfie/${numeroPraticaRif}/${codiceCliente}`;
export const GET_SELFIE_URL = (
	numeroPraticaRif: string,
	codiceCliente: string
) => `${subRoute}user/load/selfie/${numeroPraticaRif}/${codiceCliente}`;

export const OTPRequest = (
	numeroPraticaRif: string,
	codiceCliente: string //,
	//agreements: IAgreementRequest
) => `${subRoute}contract/otp/${numeroPraticaRif}/${codiceCliente}`;
//`${subRoute}contract/otp/${numeroPraticaRif}/${codiceCliente}/${agreements.flagCondizioniGenerali}/${agreements.flagManualeOperativo}/${agreements.flagInformativaPrivacy}/${agreements.flagModuloRichiesta}/${agreements.flagClausoleVessatorie}/${agreements.firma1}/${agreements.firma2}/${agreements.firma3}/${agreements.firma4}`;
export const OTPCheck = (numeroPraticaRif: string, codiceCliente: string) =>
	`${subRoute}contract/otp/send/${numeroPraticaRif}/${codiceCliente}`;
export const GET_CONTRACT_ISREADY_URL = (
	numeroPraticaRif: string,
	codiceCliente: string
) => `${subRoute}contract/isready/${numeroPraticaRif}/${codiceCliente}`;
export const GET_CONTRACT_URL = (
	numeroPraticaRif: string,
	codiceCliente: string
) => `${subRoute}contract/document/${numeroPraticaRif}/${codiceCliente}`;
export const contrattoSigned = (
	numeroPraticaRif: string,
	codiceCliente: string
) => `${subRoute}contract/document/signed/${numeroPraticaRif}/${codiceCliente}`;
