import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import Body from '../../components/body/body';
import { selfie } from '../../assets/images';
import SPCWebcam from '../../components/SPCWebcam/SPCWebcam';
import {
	selectSelfieData,
	setSelfieData,
	setStepNumber,
} from '../../store/reducers/userSlice';
import * as constPath from '../../config/constPath';

const MakeSelfie = (props: { history: string[] }) => {
	const dispatch = useDispatch();
	const [showWebcam, setShowWebcam] = useState(false);
	const selfieData = useSelector(selectSelfieData);

	const setSelfie = (imgSrc: any) => {
		dispatch(setSelfieData(imgSrc));
	};

	const forwardHandler = () => {
		dispatch(setStepNumber(constPath.NUM_STEP_CONFIRM_SELFIE));
		props.history.push(constPath.CONFIRM_SELFIE);
	};

	if (selfieData) {
		forwardHandler();
	}

	return (
		<>
			<Body
				stepNumber={3}
				title={'Fatti vedere!'}
				subtitle={
					"Il volto umano non mente mai: è l'unica cartina che segna tutti i territori in cui abbiamo vissuto.\n(Luis Sepúlveda)"
				}
				forwardPage={() => forwardHandler()}
				backPage={() => props.history.push(constPath.DOCUMENTS)}
				nextButtonIsDisabled={!selfieData}
			>
				<section className="fill">
					<fieldset className="row">
						<div className="wrap">
							<div className="legend">
								<h2>Come fare le foto</h2>
								<p>Facci sapere che sei proprio tu!</p>
								<p>Scatta una foto di te con il documento in vista.</p>
								<p>
									Prendi i documenti che hai precedentemente inserito in fase di
									richiesta e scatta una foto in cui siano ben visibili:
								</p>
								<ul>
									<li>- Volto</li>
									<li>- Documento</li>
								</ul>
								<p>
									In questa fase il documento dovrà essere mostrato sul Fronte
									(quello con la foto) così da velocizzare le procedure di
									riconoscimento e liquidazione.
								</p>
							</div>
							{showWebcam ? (
								<SPCWebcam
									setSelfie={(imageSrc: any) => {
										setSelfie(imageSrc);
									}}
								/>
							) : (
								<>
									<img
										src={selfie}
										style={{ width: '100%', height: 'auto' }}
										alt="APPpago"
										id="toggleImage"
									/>
									<div
										className="wrap"
										style={{ textAlign: 'center', marginTop: '30px' }}
									>
										<Button
											onClick={() => setShowWebcam(true)}
											className="btn sella outline small"
										>
											Scatta
										</Button>
									</div>
								</>
							)}
						</div>
					</fieldset>
				</section>
			</Body>
		</>
	);
};

export default MakeSelfie;
