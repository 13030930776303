import localForage from 'localforage';

export const basePersistConfig = {
	storage: localForage,
};

export const userPersistConfig = {
	...basePersistConfig,
	key: 'user',
	blacklist: ['errorDialogProps'],
};
