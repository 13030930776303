import React from 'react';
import { Navbar } from 'react-bootstrap';
import { imgLogo } from '../../assets/images';
import { ChatBotReact } from '../ChatBotReact/ChatBotReact';
import './Header.css';

const Header = () => {
	return (
		<>
			<Navbar className="header-navbar" fixed="top">
				<Navbar.Collapse className="justify-content-center">
					<div className="header-logo-info-container">
						<Navbar.Brand className="header-navbar-brand">
							<img src={imgLogo} className="logo-img" alt="APPpago" />
						</Navbar.Brand>
						<Navbar.Text>
							<div className="header-help">
								<p className="helpdesk">Helpdesk</p>
								<p className="schedule">lun-ven 9-18</p>
								<p className="number">800 653 365 oppure Chat</p>
							</div>
						</Navbar.Text>
					</div>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

export default Header;
