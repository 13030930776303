import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Body from '../../components/body/body';
import CountDownTimer from '../../components/countdown/CountDownTimer';
import { IUserLoadResponse } from '../../services/api/spc-be/models/ResponseModels';
import {
	getOTPRequest,
	postOTPRequest,
} from '../../services/api/spc-be/userAPI';
import { selectUserLoad, setStepNumber } from '../../store/reducers/userSlice';
import { dispatch, store } from '../../store/store';
import * as constPath from '../../config/constPath';
import './OTPSign.css';

const OTPSign = (props: any) => {
	const [currentOTPValue, setCurrentOTPValue] = useState('');
	const [date, setDate] = useState(Date.now());
	const durationMillis = 120000;
	const [invalidOTP, setInvalidOTP] = useState(false);
	const [isOTPInputDisabled, setIsOTPInputDisabled] = useState(false);
	const user: IUserLoadResponse = useSelector(selectUserLoad);

	const resetFields = () => {
		setCurrentOTPValue('');
		setIsOTPInputDisabled(false);
	};

	const sendOTP = () => {
		getOTPRequest(
			user.numeroPraticaRif,
			user.codiceCliente,
			store.getState().user.agreementTicks
		);
	};

	const checkOTP = async () => {
		const response = await postOTPRequest(
			user.numeroPraticaRif,
			user.codiceCliente,
			currentOTPValue
		);

		return response.data.errorCode === 'OK';
	};

	const forwardHandler = () => {
		dispatch(setStepNumber(constPath.NUM_STEP_COMPLETE));
		props.history.push(constPath.COMPLETE);
	};

	//	TODO IF I ARRIVE HERE NOT SEQUENTIALLY SHOULD REQUIRE SENDOTP ONLY IF NO AGREMENTTICK IN STORE
	// useEffect(() => {
	// 	sendOTP();
	// }, []);

	//const OTPRegexp = new RegExp(`^[0-9]{${constants.MAX_OTP_LENGTH}}$`);
	const OTPRegexp = new RegExp(`^[0-9]+$`);

	return (
		<>
			<Body
				stepNumber={5}
				title={'Firma contratto'}
				subtitle={'Procedi con la firma digitale del contratto.'}
				forwardPage={async () => {
					if (await checkOTP()) {
						forwardHandler();
					} else {
						setInvalidOTP(true);
					}
				}}
				backPage={() => props.history.push(constPath.AGREEMENT)}
				nextButtonIsDisabled={!currentOTPValue.match(OTPRegexp)}
			>
				<section className="otp fill">
					<fieldset className="row">
						<div className="wrap">
							<div className="legend">
								<p>
									Controlla il tuo smartphone. Ti abbiamo appena inviato un
									codice per poter confermare e firmare il contratto in modo
									digitale.
								</p>
								<p>
									Il codice inviato ci aiuta a verificare che i tuoi dati non
									siano stati utilizzati in modo improprio da terze persone.
								</p>
								<p>
									Inserisci il codice prima che il tempo finisca o richiedi un
									nuovo codice:
								</p>
							</div>
							<Row>
								<Col className="col-6 offset-3 centered">
									<CountDownTimer
										startingDateMillis={date}
										durationMillis={durationMillis}
										onComplete={() => {
											setCurrentOTPValue('');
											setIsOTPInputDisabled(true);
										}}
									/>
								</Col>
							</Row>
							<Form className="otp-sign-form">
								<Form.Row>
									<Col className="col-6 offset-3 centered">
										<Form.Control
											onChange={(e) => setCurrentOTPValue(e.target.value)}
											type="text"
											placeholder="Codice di verifica"
											disabled={isOTPInputDisabled}
										/>
										<br />
										{invalidOTP && (
											<p className="invalid-otp-message" align="center">
												Codice non valido. Riprovare
											</p>
										)}
									</Col>
								</Form.Row>
							</Form>

							<div className="centered">
								<small>
									Non l'hai ricevuto?
									<Link
										onClick={async () => {
											setDate(Date.now());
											sendOTP();
											resetFields();
										}}
									>
										{' Richiedi un nuovo codice'}
									</Link>
								</small>
							</div>
						</div>
					</fieldset>
				</section>
			</Body>
		</>
	);
};

export default OTPSign;
