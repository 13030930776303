import axios, { AxiosPromise } from 'axios';
import httpSendReqSpc from './axiosSPCBE';
import {
	cfFronteURL,
	cfRetroURL,
	ciFronteURL,
	ciRetroURL,
	GET_CONTRACT_URL,
	GET_CONTRACT_ISREADY_URL,
	contrattoSigned,
	currentStatusURL,
	GET_SELFIE_URL,
	OTPCheck,
	OTPRequest,
	postselfieURL,
	signedPrivacyURL,
	unsignedPrivacyURL,
	USER_LOAD_URL,
} from './endPoints';
import {
	IAgreementRequest,
	IBase64DocumentRequest,
	ILandingRequest,
	ISignPrivacyRequest,
} from './models/RequestModels';
import {
	IAgreementReadyResponse,
	IBase64DocumentResponse,
	ICurrentStatusResponse,
	IUserLoadResponse,
} from './models/ResponseModels';

export function postUserLoad(
	data: ILandingRequest
): AxiosPromise<IUserLoadResponse> {
	return httpSendReqSpc.post(USER_LOAD_URL, data);
}

export function getStatus(
	codiceCliente: string,
	numeroPraticaRif: string
): AxiosPromise<ICurrentStatusResponse> {
	return httpSendReqSpc.get(currentStatusURL(codiceCliente, numeroPraticaRif));
}

export function getPrivacyDocument(
	codiceCliente: ILandingRequest['codiceCliente'],
	numeroPraticaRif: ILandingRequest['numeroPraticaRif']
): AxiosPromise<IBase64DocumentResponse> {
	return httpSendReqSpc.get(
		`${unsignedPrivacyURL}/${codiceCliente}/${numeroPraticaRif}`
	);
}

export function postSignedPrivacy(
	codiceCliente: string,
	tipoPraticaRif: string,
	numeroPraticaRif: string,
	body: ISignPrivacyRequest
): AxiosPromise<any> {
	return httpSendReqSpc.post(
		signedPrivacyURL(tipoPraticaRif, numeroPraticaRif, codiceCliente),
		body
	);
}

export function getCIFronte(
	codiceCliente: string,
	numeroPraticaRif: string
): AxiosPromise<any> {
	return httpSendReqSpc.get(ciFronteURL(codiceCliente, numeroPraticaRif));
}

export function getCIRetro(
	codiceCliente: string,
	numeroPraticaRif: string
): AxiosPromise<any> {
	return httpSendReqSpc.get(ciRetroURL(codiceCliente, numeroPraticaRif));
}

export function getCFFronte(
	codiceCliente: string,
	numeroPraticaRif: string
): AxiosPromise<any> {
	return httpSendReqSpc.get(cfFronteURL(codiceCliente, numeroPraticaRif));
}

export function getCFRetro(
	codiceCliente: string,
	numeroPraticaRif: string
): AxiosPromise<any> {
	return httpSendReqSpc.get(cfRetroURL(codiceCliente, numeroPraticaRif));
}

export function postSelfie(
	codiceCliente: string,
	numeroPraticaRif: string,
	body: IBase64DocumentRequest
): AxiosPromise<any> {
	return httpSendReqSpc.post(
		postselfieURL(numeroPraticaRif, codiceCliente),
		body
	);
}

export function getSelfieURL(
	numeroPraticaRif: string,
	codiceCliente: string
): AxiosPromise<any> {
	return httpSendReqSpc.get(GET_SELFIE_URL(numeroPraticaRif, codiceCliente));
}

export function getAgreementDocument(
	codiceCliente: ILandingRequest['codiceCliente'],
	numeroPraticaRif: ILandingRequest['numeroPraticaRif']
): AxiosPromise<IBase64DocumentResponse> {
	return httpSendReqSpc.get(GET_CONTRACT_URL(numeroPraticaRif, codiceCliente));
}

export function getSignedAgreementDocument(
	codiceCliente: ILandingRequest['codiceCliente'],
	numeroPraticaRif: ILandingRequest['numeroPraticaRif']
): AxiosPromise<IBase64DocumentResponse> {
	return httpSendReqSpc.get(contrattoSigned(codiceCliente, numeroPraticaRif));
}

export function getAgreementDocumentIsReady(
	codiceCliente: ILandingRequest['codiceCliente'],
	numeroPraticaRif: ILandingRequest['numeroPraticaRif'],
	silentCall?: boolean
): AxiosPromise<IAgreementReadyResponse> {
	const url = GET_CONTRACT_ISREADY_URL(numeroPraticaRif, codiceCliente);

	if (silentCall) {
		const uninterceptedAxiosInstance = axios.create();
		return uninterceptedAxiosInstance.get(url);
	} else {
		return httpSendReqSpc.get(url);
	}
}

export function getOTPRequest(
	codiceCliente: ILandingRequest['codiceCliente'],
	numeroPraticaRif: ILandingRequest['numeroPraticaRif'],
	agreementList: IAgreementRequest
): AxiosPromise<IAgreementReadyResponse> {
	//OTPRequest(codiceCliente, numeroPraticaRif, agreementList)
	return httpSendReqSpc.get(OTPRequest(codiceCliente, numeroPraticaRif));
}

export function postOTPRequest(
	codiceCliente: ILandingRequest['codiceCliente'],
	numeroPraticaRif: ILandingRequest['numeroPraticaRif'],
	otp: string
): AxiosPromise<IAgreementReadyResponse> {
	return httpSendReqSpc.post(OTPCheck(codiceCliente, numeroPraticaRif), {
		otp: otp,
	});
}
