import React from 'react';
import { useSelector } from 'react-redux';
import Body from '../../components/body/body';
import PDFViewer from '../../components/pdfViewer/pdfViewer';
import useSignedAgreement from '../../hooks/useSignedAgreement';
import { IUserLoadResponse } from '../../services/api/spc-be/models/ResponseModels';
import { selectUserLoad } from '../../store/reducers/userSlice';
import { downloadUnsignedPDF } from '../../utils/browserUtils';
import './Complete.css';

const OPTSign = (props) => {
	const user: IUserLoadResponse = useSelector(selectUserLoad);
	const { signedAgreement } = useSignedAgreement(
		user.numeroPraticaRif,
		user.codiceCliente
	);

	return (
		<>
			<Body
				stepNumber={7}
				title={'Hai terminato!'}
				subtitle={
					'Puoi ora prendere visione del contratto e scaricarne una copia in pdf cliccando sul bottone "Scarica il pdf".'
				}
				showNavButton={false}
			>
				<section className="otp fill">
					<fieldset className="row">
						<div className="wrap">
							<p>Grazie per aver completato la richiesta!</p>
							<p>Speriamo che abbia apprezzato la nostra proposta.</p>
							<p>
								Adesso avrai modo di conservare una copia del contratto,
								scaricandolo sul tuo dispositivo smartphone, desktop o tablet.
							</p>
							<div className="commands complete-button centered">
								<a
									className="btn sella small outline"
									rel="noopener noreferrer"
									onClick={() =>
										downloadUnsignedPDF(signedAgreement, 'contratto_firmato')
									}
								>
									Scarica il pdf
								</a>
							</div>
							<div>
								<p>Di seguito trovi l’anteprima del contratto</p>
								<PDFViewer file={signedAgreement}></PDFViewer>
							</div>
						</div>
					</fieldset>
				</section>
			</Body>
		</>
	);
};

export default OPTSign;
