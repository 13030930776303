import axios from 'axios';
import { setErrorDialogProps } from '../../../store/reducers/userSlice';
import { dispatch, store } from '../../../store/store';

export const API_BASE_URL = process.env.REACT_APP_URL_SPCBE;

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export function loadAxiosSPCBEConfigs(
	onRequest: Function,
	onResponse: Function,
	onError: Function
) {
	let callsNumber = 0;

	axios.interceptors.request.use(
		(config) => {
			if (callsNumber == 0) {
				onRequest();
			}
			callsNumber++;
			return config;
		},
		(error) => {
			callsNumber--;
			if (callsNumber == 0) {
				onError();
			}
			dispatch(
				setErrorDialogProps({
					...store.getState().user.errorDialogProps,
					message: error.toString(),
					isVisible: true,
				})
			);
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		(response) => {
			callsNumber--;
			if (callsNumber == 0) {
				onResponse();
			}
			return response;
		},
		(error) => {
			callsNumber--;
			if (callsNumber == 0) {
				onError();
			}
			console.debug(`An error occurred making request...${error}`);

			// if (error.response && error.response.status === 401) {
			// 	axios.defaults.headers.common.Cookie = null;
			// } else {
			dispatch(
				setErrorDialogProps({
					...store.getState().user.errorDialogProps,
					message: error.toString(),
					isVisible: true,
				})
			);

			return Promise.reject(error);
			//}
		}
	);
}

const httpSendReqSpc = {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	patch: axios.patch,
	delete: axios.delete,
};

export default httpSendReqSpc;
