import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SPCRoute from '../components/SPCRoute/SPCRoute';
import { ErrorPage } from '../containers';
import { ERROR_PAGE, LANDING } from './constPath';
import * as constPath from './constPath';
import { store } from '../store/store';
import { objectToQueryString } from '../utils/objUtils';
import { initialLandingParams } from './initialStoreData';

export function getIndexPath() {
	const currentLandingParams = store.getState().user.currentLandingParams;
	return currentLandingParams !== initialLandingParams
		? `${LANDING}${objectToQueryString(currentLandingParams)}`
		: LANDING;
}

const SPCRouter = (props: any) => {
	return (
		<Switch>
			{/*"index?token=c2586f88-1ebe-11eb-adc1-0242ac120002&name=Mario&lastname=Rossi&fiscalCode=MRTNRF73E29E507F&amount=338,50"*/}
			<Route
				exact
				path="/"
				render={() => (
					<Redirect to={{ search: props.location.search, pathname: LANDING }} />
				)}
			/>

			<SPCRoute path={constPath.LANDING} />
			<SPCRoute path={constPath.PRIVACY} />
			<SPCRoute path={constPath.DOCUMENTS} />
			<SPCRoute path={constPath.MAKE_SELFIE} />
			<SPCRoute path={constPath.CONFIRM_SELFIE} />
			<SPCRoute path={constPath.AGREEMENT} />
			<SPCRoute path={constPath.OTP_SIGN} />
			<SPCRoute path={constPath.COMPLETE} />
			<Route path={ERROR_PAGE} component={ErrorPage} />
			<Redirect to={ERROR_PAGE} />
		</Switch>
	);
};

export default SPCRouter;
