import { initialUserLoad } from '../../config/initialStoreData';
import { IUserLoadResponse } from '../../services/api/spc-be/models/ResponseModels';
import { postUserLoad, getStatus } from '../../services/api/spc-be/userAPI';
import { resetUserSlice } from '../../store/reducers/resetUserSlice';
import {
	setUserLoad,
	setStepNumber,
	setErrorDialogProps,
	setCurrentLandingParams,
} from '../../store/reducers/userSlice';
import { dispatch, store } from '../../store/store';

export async function getCurrentStatus(
	loadedUser: IUserLoadResponse,
	onDifferentCurrentStep: any
) {
	const prevStepNumber = store.getState().user.stepNumber;

	getStatus(loadedUser.codiceCliente, loadedUser.numeroPraticaRif).then(
		(statusResponse) => {
			const currentStatus = statusResponse.data.currentStatus;

			if (prevStepNumber != currentStatus) {
				dispatch(setStepNumber(currentStatus));
				if (currentStatus != 0) {
					onDifferentCurrentStep(currentStatus);
				}
			}
		}
	);
}

export function resetApplicationDatas() {
	resetUserSlice();
}

export async function initUserLoad(
	srcLandingData: any,
	onDifferentCurrentStep: any
) {
	try {
		const response = await postUserLoad(srcLandingData);
		const loadedUser = response.data;
		dispatch(setUserLoad(loadedUser));
		getCurrentStatus(loadedUser, onDifferentCurrentStep);
	} catch (error) {
		dispatch(setUserLoad(initialUserLoad));
		dispatch(
			setErrorDialogProps({
				...store.getState().user.errorDialogProps,
				message: 'Sono stati inviati parametri scorretti',
				isVisible: true,
			})
		);
	} finally {
		resetApplicationDatas();
		dispatch(setCurrentLandingParams(srcLandingData));
	}
}
