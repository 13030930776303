import React from 'react';
import Slot from './slot';
import './SPCSlotMachine.css';
import slot3 from '../../assets/images/slot3_APPpago.gif';
import slot2 from '../../assets/images/slot2_APPpago.gif';
import slot1 from '../../assets/images/slot1_APPpago.gif';
import slot1_first_frame from '../../assets/images/slot1_APPpago.png';
import slot2_first_frame from '../../assets/images/slot2_APPpago.png';
import slot3_first_frame from '../../assets/images/slot3_APPpago.png';

import { useSelector } from 'react-redux';
import { selectUserLoad } from '../../store/reducers/userSlice';
import { IUserLoadResponse } from '../../services/api/spc-be/models/ResponseModels';
import { initialUserLoad } from '../../config/initialStoreData';

interface IProps {
	style?: any;
	couldStartAnimation: boolean;
}

const Slots = (props: IProps) => {
	const userLoad: IUserLoadResponse = useSelector(selectUserLoad);
	return (
		<div className="SlotMachine" style={props.style}>
			<Slot
				gif={slot1}
				firstFrame={slot1_first_frame}
				couldStartAnimation={props.couldStartAnimation}
				time={1680}
			>
				<div className="credit-label">IMPORTO DEL CREDITO</div>
				<div className="credit">{userLoad.importoFinanziato}€</div>
				<div className="tot-label">totale dovuto</div>
				<div className="tot">{Number(userLoad.importoTotale)}€</div>
			</Slot>
			<Slot
				gif={slot2}
				firstFrame={slot2_first_frame}
				couldStartAnimation={props.couldStartAnimation}
				time={2280}
			>
				<div className="rate-label">RATA MENSILE</div>
				<div className="rate">{Number(userLoad.importoRata).toFixed(2)}€</div>
				<div className="time-rate">per {Number(userLoad.numeroRate)} rate</div>
				<div className="tot">&nbsp;</div>
			</Slot>
			<Slot
				gif={slot3}
				firstFrame={slot3_first_frame}
				couldStartAnimation={props.couldStartAnimation}
				time={2680}
			>
				<div className="taeg-label">TAEG</div>
				<div className="taeg">{userLoad.taeg}%*</div>
				<div className="tan-label">TAN fisso</div>
				<div className="tan">{userLoad.tan}%</div>
			</Slot>
		</div>
	);
};

export default Slots;
