import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PracticeState } from '../store';
import { IUserLoadResponse } from '../../services/api/spc-be/models/ResponseModels';
import {
	IAgreementRequest,
	ILandingRequest,
	ISignPrivacyRequest,
} from '../../services/api/spc-be/models/RequestModels';
import { IConfirmDialogProps } from '../../components/confirmDialog/ConfirmDialog';
import {
	initialUserLoad,
	initialPrivacyFirstStepTicks,
	initialErrorDialogProps,
	initialInfoDialogProps,
	initialLandingParams,
	initialAgreementRequest,
} from '../../config/initialStoreData';
import { PrivacyTickProps } from '../../components/privacyTick/privacyTick';

export interface IUserSlice {
	stepNumber: number;
	selfieData: any;
	userLoad: IUserLoadResponse;
	privacyFirstStepTicks: ISignPrivacyRequest;
	privacyTickProps: PrivacyTickProps[];
	signedPrivacyDocumentBase64: any;
	errorDialogProps: IConfirmDialogProps;
	infoDialogProps: IConfirmDialogProps;
	currentLandingParams: ILandingRequest;
	agreementTicks: IAgreementRequest;
}

export const userInitialState: IUserSlice = {
	stepNumber: 0,
	selfieData: null,
	userLoad: initialUserLoad,
	privacyFirstStepTicks: initialPrivacyFirstStepTicks,
	privacyTickProps: new Array(),
	signedPrivacyDocumentBase64: null,
	errorDialogProps: initialErrorDialogProps,
	infoDialogProps: initialInfoDialogProps,
	currentLandingParams: initialLandingParams,
	agreementTicks: initialAgreementRequest,
};

export const userSlice = createSlice({
	name: 'user',
	initialState: userInitialState,
	reducers: {
		setUserLoad: (state, action: PayloadAction<IUserLoadResponse>) => {
			state.userLoad = action.payload;
		},
		setStepNumber: (state, action: PayloadAction<number>) => {
			state.stepNumber = action.payload;
		},
		setSelfieData: (state, action: PayloadAction<any>) => {
			state.selfieData = action.payload;
		},
		setPrivacyFirstStepTicks: (
			state,
			action: PayloadAction<ISignPrivacyRequest>
		) => {
			state.privacyFirstStepTicks = action.payload;
		},
		setPrivacyTickProps: (state, action: PayloadAction<PrivacyTickProps[]>) => {
			state.privacyTickProps = action.payload;
		},
		setSignedPrivacyDocumentBase64: (state, action: PayloadAction<any>) => {
			state.signedPrivacyDocumentBase64 = action.payload;
		},
		setErrorDialogProps: (
			state,
			action: PayloadAction<IConfirmDialogProps>
		) => {
			state.errorDialogProps = action.payload;
		},
		setInfoDialogProps: (state, action: PayloadAction<IConfirmDialogProps>) => {
			state.infoDialogProps = action.payload;
		},
		setCurrentLandingParams: (
			state,
			action: PayloadAction<ILandingRequest>
		) => {
			state.currentLandingParams = action.payload;
		},
		setAgreementTicks: (state, action: PayloadAction<IAgreementRequest>) => {
			state.agreementTicks = action.payload;
		},
	},
});

export const {
	setUserLoad,
	setStepNumber,
	setSelfieData,
	setPrivacyFirstStepTicks,
	setPrivacyTickProps,
	setSignedPrivacyDocumentBase64,
	setErrorDialogProps,
	setInfoDialogProps,
	setCurrentLandingParams,
	setAgreementTicks,
} = userSlice.actions;

export const selectUserLoad = (state: PracticeState): IUserLoadResponse =>
	state.user.userLoad;

export const selectStepNumber = (state: PracticeState): number =>
	state.user.stepNumber;

export const selectSelfieData = (state: PracticeState): any =>
	state.user.selfieData;

export const selectPrivacyFirstStepTicks = (state: PracticeState): any =>
	state.user.privacyFirstStepTicks;

export const selectPrivacyTickProps = (state: PracticeState): any =>
	state.user.privacyTickProps;

export const selectSignedPrivacyDocumentBase64 = (state: PracticeState): any =>
	state.user.signedPrivacyDocumentBase64;

export const selectErrorDialogProps = (state: PracticeState): any =>
	state.user.errorDialogProps;

export const selectInfoDialogProps = (state: PracticeState): any =>
	state.user.infoDialogProps;

export const selectCurrentLandingParams = (state: PracticeState): any =>
	state.user.currentLandingParams;

export const selectAgreementTicks = (state: PracticeState): any =>
	state.user.agreementTicks;

export default userSlice.reducer;
