import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Button, Row } from 'react-bootstrap';
import cfDefaultFront from '../../assets/img/cf-front.jpg';
import cfDefaultBack from '../../assets/img/cf-back.jpg';
import ciDefaultFront from '../../assets/img/ci-front.jpg';
import ciDefaultBack from '../../assets/img/ci-back.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Documents.css';
import CustomModal from '../../components/customModal/CustomModal';
import {
	getCIFronte,
	getCIRetro,
	getCFFronte,
	getCFRetro,
} from '../../services/api/spc-be/userAPI';
import {
	selectUserLoad,
	setErrorDialogProps,
	setStepNumber,
} from '../../store/reducers/userSlice';
import Body from '../../components/body/body';
import * as constPath from '../../config/constPath';
import { store } from '../../store/store';

const Documents = (props: any) => {
	const userLoadInfo = useSelector(selectUserLoad);
	const [ciFronte, setCIFronte] = useState(ciDefaultFront);
	const [ciRetro, setCIRetro] = useState(ciDefaultBack);
	const [cfFronte, setCFFronte] = useState(cfDefaultFront);
	const [cfRetro, setCFRetro] = useState(cfDefaultBack);

	const [showModal, setShowModal] = useState(false);
	const [img, setImg] = useState('');

	const dispatch = useDispatch();

	const forwardHandler = () => {
		dispatch(setStepNumber(constPath.NUM_STEP_MAKE_SELFIE));
		props.history.push(constPath.MAKE_SELFIE);
	};

	const handleClickImg = (img: string) => {
		setImg(img);
		setShowModal(true);
	};

	const showDocoumentNotFoundDialog = (error: any) => {
		if (error.response && error.response.status == 404) {
			dispatch(
				setErrorDialogProps({
					...store.getState().user.errorDialogProps,
					message: 'Documenti Non trovati',
					//isVisible: true,
				})
			);
		}
	};

	useEffect(() => {
		getCIFronte(userLoadInfo.codiceCliente, userLoadInfo.numeroPraticaRif)
			.then((res) => {
				setCIFronte(`data:image/png;base64,${res.data.document}`);
			})
			.catch(showDocoumentNotFoundDialog);
		getCIRetro(userLoadInfo.codiceCliente, userLoadInfo.numeroPraticaRif)
			.then((res) => {
				setCIRetro(`data:image/png;base64,${res.data.document}`);
			})
			.catch(showDocoumentNotFoundDialog);

		getCFFronte(userLoadInfo.codiceCliente, userLoadInfo.numeroPraticaRif)
			.then((res) => {
				setCFFronte(`data:image/png;base64,${res.data.document}`);
			})
			.catch(showDocoumentNotFoundDialog);

		getCFRetro(userLoadInfo.codiceCliente, userLoadInfo.numeroPraticaRif)
			.then((res) => {
				setCFRetro(`data:image/png;base64,${res.data.document}`);
			})
			.catch(showDocoumentNotFoundDialog);
	}, []);

	return (
		<>
			<Body
				stepNumber={2}
				title={'Verifica i tuoi documenti'}
				subtitle={
					'È arrivato il momento delle presentazioni... ma grazie ad APPpago ci siamo già conosciuti. Facci sapere se i contenuti di questa pagina sono corretti e... procediamo verso il ritiro del tuo premio!'
				}
				forwardPage={() => forwardHandler()}
				backPage={() => props.history.push(constPath.PRIVACY)}
			>
				<section className="fill">
					<fieldset className="row">
						<div className="wrap">
							<div className="legend">
								<h2>Carta d'identit&agrave; e Tessera Sanitaria</h2>
								<p>
									Controlla di seguito che quelli proposti siano i tuoi
									documenti personali. Devono coincidere con quelli utilizzati
									durante la richiesta di utilizzo APPpago.
								</p>
							</div>
							<Row>
								<Col className="col-6">
									<div>
										<p>Fronte</p>
										<div className="drop">
											<div
												style={{
													backgroundImage: `url(${ciFronte})`,
												}}
												className="preview ci-front"
											></div>
											<Button
												className="preview-button"
												variant="primary"
												size="sm"
												onClick={() => handleClickImg(ciFronte)}
											>
												Apri
											</Button>
										</div>
									</div>
								</Col>
								<Col className="col-6">
									<div>
										<p>Retro</p>
										<div className="drop">
											<div
												style={{
													backgroundImage: `url(${ciRetro})`,
												}}
												className="preview ci-back"
											></div>
											<Button
												className="preview-button"
												variant="primary"
												size="sm"
												onClick={() => handleClickImg(ciRetro)}
											>
												Apri
											</Button>
										</div>
									</div>
								</Col>
							</Row>
							<Row>
								<p>{'\n'}</p>
							</Row>
							<Row>
								<Col className="col-6">
									<div>
										<p>Fronte</p>
										<div className="drop">
											<div
												className="preview cf-front"
												style={{
													backgroundImage: `url(${cfFronte})`,
												}}
											></div>
											<Button
												className="preview-button"
												variant="primary"
												size="sm"
												onClick={() => handleClickImg(cfFronte)}
											>
												Apri
											</Button>
										</div>
									</div>
								</Col>
								<Col className="col-6">
									<div>
										<p>Retro</p>
										<div className="drop">
											<div
												style={{
													backgroundImage: `url(${cfRetro})`,
												}}
												className="preview cf-back"
											></div>
											<Button
												className="preview-button"
												variant="primary"
												size="sm"
												onClick={() => handleClickImg(cfRetro)}
											>
												Apri
											</Button>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</fieldset>
				</section>
			</Body>
			{showModal && (
				<CustomModal
					show={showModal}
					src={img}
					handleClose={() => {
						setShowModal(false);
					}}
				/>
			)}
		</>
	);
};

export default Documents;
