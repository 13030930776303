import {
	Agreement,
	Complete,
	ConfirmSelfie,
	Documents,
	Landing,
	MakeSelfie,
	OTPSign,
	Privacy,
} from '../containers';

export const LANDING = '/index';
export const ERROR_PAGE = '/stepError';
export const PRIVACY = '/step1';
export const DOCUMENTS = '/step2';
export const MAKE_SELFIE = '/step3';
export const CONFIRM_SELFIE = '/step4';
export const AGREEMENT = '/step5';
export const OTP_SIGN = '/step6';
export const COMPLETE = '/step7';

export const NUM_STEP_LANDING = 0;
export const NUM_STEP_PRIVACY = 1;
export const NUM_STEP_DOCUMENTS = 2;
export const NUM_STEP_MAKE_SELFIE = 3;
export const NUM_STEP_CONFIRM_SELFIE = 4;
export const NUM_STEP_AGREEMENT = 5;
export const NUM_STEP_OTP_SIGN = 6;
export const NUM_STEP_COMPLETE = 7;

export const routes = {
	[LANDING]: { stepNumber: NUM_STEP_LANDING, component: Landing },
	[PRIVACY]: { stepNumber: NUM_STEP_PRIVACY, component: Privacy },
	[DOCUMENTS]: { stepNumber: NUM_STEP_DOCUMENTS, component: Documents },
	[MAKE_SELFIE]: { stepNumber: NUM_STEP_MAKE_SELFIE, component: MakeSelfie },
	[CONFIRM_SELFIE]: {
		stepNumber: NUM_STEP_CONFIRM_SELFIE,
		component: ConfirmSelfie,
	},
	[AGREEMENT]: { stepNumber: NUM_STEP_AGREEMENT, component: Agreement },
	[OTP_SIGN]: { stepNumber: NUM_STEP_OTP_SIGN, component: OTPSign },
	[COMPLETE]: { stepNumber: NUM_STEP_COMPLETE, component: Complete },
} as Record<string, { stepNumber: number; component: any }>;
